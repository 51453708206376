export const indicators = {
  currency: 'EUR',
  vatRate: 0.16,
  vatIncluded: true,
  shippingCost: 2.00,
  shippingThreshhold: 0,
  shippingCountries: ['germany', 'austria', 'switzerland'],
}

export const formatCash = (value) => (
  new Intl.NumberFormat(
    'de-DE',
    { style: 'currency', currency: indicators.currency },
  ).format(Number(value))
);

export const capitalizeFirst = (value) => {
  const valueString = value.toString();
  return valueString.charAt(0).toUpperCase() + valueString.slice(1);
};
