import React, { Component } from 'react';
import PropTypes from 'prop-types';
import useScript from '../../../../utilities/js/hooks.script';
import isProduction from '../../../../utilities/js/is-production';

// force sandbox
// TODO: remove in final build
//const isProduction = false;


//TODO: fetch credentials from server
const credentials = {
  merchantID: 'A3VYYJUZF9OWCF',
  clientID: 'amzn1.application-oa2-client.9329dbb6112a491b9e57fcaae828c6f5',
}

const mainSDK = `
  https://static-eu.payments-amazon.com/OffAmazonPayments/de/${isProduction ? '' : 'sandbox/'}lpa/js/Widgets.js
`;

/*eslint-disable no-undef*/
/*eslint-disable no-unused-vars*/

window.onAmazonLoginReady = () => {
  amazon.Login.setClientId(credentials.clientID);
  amazon.Login.setUseCookie(true);
}

const AmazonPay = ({ config: { merchantID, clientID, route } }) => {
  const [widgetsLoaded, widgetsError] = useScript(mainSDK);
  const [loginLoaded] = useScript('https://api-cdn.amazon.com/sdk/login1.js');
  return (
    <div>
      {widgetsLoaded && loginLoaded && !widgetsError && (
        <AmazonBtn
          OffAmazonPayments={OffAmazonPayments}
          amazon={amazon}
          credentials={{ merchantID, clientID }}
          route={route}
        />
      )}
    </div>
  );
}

AmazonPay.propTypes = {
  config: PropTypes.shape({
    merchantID: PropTypes.string,
    clientID: PropTypes.string,
    route: PropTypes.string,
  }),
}

class AmazonBtn extends Component {
  static propTypes = {
    OffAmazonPayments: PropTypes.object,
    amazon: PropTypes.object,
    credentials: PropTypes.shape({
      merchantID: PropTypes.string,
      clientID: PropTypes.string,
    }),
    route: PropTypes.string,
  }

  componentDidMount() {
    const {
      OffAmazonPayments, amazon, credentials, route,
    } = this.props;

    amazon.Login.logout();

    let authRequest;
    
    OffAmazonPayments.Button('AmazonPayButton', credentials.merchantID, {
      type: "PwA",
      authorization: () => {
        authRequest = amazon.Login.authorize({
          scope: 'profile postal_code payments:widget payments:shipping_address',
          popup: true,
        }, route);
      },
      onError: (error) => {
        //console.log(error);
        console.error(
          'LwA error: ',
          error.getErrorCode(),
          '\n',
          error.getErrorMessage()
        );
      }
    });
  }

  render() {
    return <div id="AmazonPayButton"></div>;
  }
}

export default AmazonPay;
