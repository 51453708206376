import React from 'react';

const __defaultValue = (value, defaultValue) => {
  return value ? value : defaultValue;
}

const RenderSvg = ({
  width, height, viewBow, fillRule, clipRule, svgPath
}) => (
  <svg
    width={__defaultValue(width, '24')}
    height={__defaultValue(height, '24')}
    viewBox={__defaultValue(viewBow, '0 0 24 24')}
    fillRule={__defaultValue(fillRule, 'evenodd')}
    clipRule={__defaultValue(clipRule, 'evenodd')}
    style={ width || height ? { width, height } : {}}
  >
    <path d={svgPath} />
  </svg>
);

class Icons {
  Basic = {
    Success() {
      return(
        <RenderSvg
          svgPath="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12
            5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11
            4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649
            4.271 5.016 8.24-8.752.728.685z"
        />
      );
    },
    Error() {
      return(
        <RenderSvg
          svgPath="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12
            5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11
            11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293
            5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707
            5.293-5.293-5.293-5.293.707-.707 5.293 5.293z"
        />
      );
    }
  }

  Navigation = {
    ArrowTop() {
      return(
        <RenderSvg
          svgPath="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479
            9-.764.646-6.236-7.53v21.884h-1v-21.883z"
        />
      );
    },
    ArrowBottom() {
      return(
        <RenderSvg
          svgPath="M11 21.883l-6.235-7.527-.765.644 7.521 9
            7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"
        />
      );
    },
    ArrowLeft() {
      return(
        <RenderSvg
          svgPath="M2.117 12l7.527 6.235-.644.765-9-7.521
            9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"
        />
      );
    },//viewBox="-10 -10 50 50"?
    Logout() {
      return(
        <RenderSvg
          svgPath="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z"
        />
      );
    }
  }

  Controls = {
    Reduce() {
      return (
        <RenderSvg
          svgPath="M0 10h24v4h-24z"
        />
      );
    },
    Increment() {
      return (
        <RenderSvg
          svgPath="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
        />
      );
    },
    Delete() {
      return (
        <RenderSvg
          svgPath="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0
            1.104-.896 2-2 2m3-19h-20v-2h6v-1.5c0-.827.673-1.5
            1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-12-2h4v-1h-4v1z"
        />
      );
    },
    Next() {
      return(
        <RenderSvg
          svgPath="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
        />
      );
    }
  }

  Shop = {
    Bag() {
      return(
        <RenderSvg
          svgPath="M24 3l-.743 2h-1.929l-3.474 12h-13.239l-4.615-11h16.812l-.564
            2h-13.24l2.937 7h10.428l3.432-12h4.195zm-15.5 15c-.828 0-1.5.672-1.5 1.5 0
            .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.9-7-1.9
            7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z"
        />
      );
    },
    Cart() {
      return(
        <RenderSvg
          svgPath="M16 6v-2c0-2.209-1.791-4-4-4s-4 1.791-4 4v2h-5v18h18v-18h-5zm-7-2c0-1.654
            1.346-3 3-3s3 1.346 3 3v2h-6v-2zm10 18h-14v-14h3v1.5c0
            .276.224.5.5.5s.5-.224.5-.5v-1.5h6v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h3v14z"
        />
      );
    },
    Invoice() {
      return(
        <RenderSvg
          svgPath="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6
            2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811
            13h-10v-1h10v1zm0 2h-10v1h10v-1zm0 3h-10v1h10v-1z"
        />
      );
    },
    Bitcoin() {
      return(
        <RenderSvg
          svgPath="M11 24v-3.022h-1v3.022h-2v-3.022h-4.969l.5-2.978h1.079c.884
            0 1.39-.851 1.39-1.707v-8.889c0-.833-.485-1.404-1.365-1.404h-1.635v-3h5
            v-3h2v3h1v-3h2v3.053c4.315.146 6.024 1.781 6.514 3.625.58 2.18-.857
            4.01-2.093 4.456 1.501.382 3.579 1.491 3.579 4.05 0 3.483-2.688 5.816-8
            5.816v3h-2zm-1-11.006v5.006c3.969 0 6.688-.375 6.688-2.516
            0-2.296-2.938-2.49-6.688-2.49zm0-1.994c2.211 0 5.578-.156 5.578-2.5
            0-2-2.078-2.5-5.578-2.5v5z"
        />
      );
    },
    Paypal() {
      return(
        <RenderSvg
          svgPath="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0
            22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm5.5-11.119c0
            .268-.033.542-.085.813-.424 2.209-1.873 2.973-3.724 2.973h-.286c-.226 0-.419.167-.454.394l-.402
            2.545c-.035.227-.227.394-.454.394h-1.368c-.195 0-.344-.174-.314-.366l.693-4.44.031-.028h1.077c2.618
            0 4.255-1.309 4.736-3.784.407.407.55.938.55 1.499zm-7.108 2.125c.058-.413.23-.589.693-.59l1.13-.001c2.287
            0 3.599-1.045 4.011-3.195.4-2.066-1.051-3.22-3.015-3.22h-3.673c-.258 0-.478.191-.518.451-1.152 7.418-1.485
            9.304-1.519 9.879-.011.181.134.336.318.336h1.995l.578-3.66z"
        />
      );
    },
    Amazon() {
      return(
        <RenderSvg
          svgPath="M13.958 10.09c0 1.232.029 2.256-.591 3.351-.502.891-1.301 1.438-2.186
            1.438-1.214 0-1.922-.924-1.922-2.292 0-2.692 2.415-3.182 4.7-3.182v.685zm3.186
            7.705c-.209.189-.512.201-.745.074-1.052-.872-1.238-1.276-1.814-2.106-1.734 1.767-2.962
            2.297-5.209 2.297-2.66 0-4.731-1.641-4.731-4.925 0-2.565 1.391-4.309 3.37-5.164 1.715-.754
            4.11-.891 5.942-1.095v-.41c0-.753.06-1.642-.383-2.294-.385-.579-1.124-.82-1.775-.82-1.205 0-2.277.618-2.54
            1.897-.054.285-.261.567-.549.582l-3.061-.333c-.259-.056-.548-.266-.472-.66.704-3.716 4.06-4.838 7.066-4.838
            1.537 0 3.547.41 4.758 1.574 1.538 1.436 1.392 3.352 1.392 5.438v4.923c0 1.481.616 2.13 1.192
            2.929.204.287.247.63-.01.839-.647.541-1.794 1.537-2.423 2.099l-.008-.007zm3.559 1.988c-2.748
            1.472-5.735 2.181-8.453 2.181-4.027 0-7.927-1.393-11.081-3.706-.277-.202-.481.154-.251.416
            2.925 3.326 6.786 5.326 11.076 5.326 3.061 0 6.614-1.214 9.066-3.494.406-.377.058-.945-.357-.723zm.67
            2.216c-.091.227.104.32.31.147 1.339-1.12 1.685-3.466 1.411-3.804-.272-.336-2.612-.626-4.04.377-.22.154-.182.367.062.337.805-.096
            2.595-.312 2.913.098.319.41-.355 2.094-.656 2.845z"
        />
      );
    },
    Credit() {
      return(
        <RenderSvg
          svgPath="M21.5 6c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-11c0-.276.224-.5.5-.5h19zm2.5
            0c0-1.104-.896-2-2-2h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0
            2-.896 2-2v-12zm-20 3.78c0-.431.349-.78.78-.78h.427v1.125h-1.207v-.345zm0 .764h1.208v.968h-1.208v-.968zm0
            1.388h1.208v1.068h-.428c-.431 0-.78-.349-.78-.78v-.288zm4 .288c0
            .431-.349.78-.78.78h-.429v-1.068h1.209v.288zm0-.708h-1.209v-.968h1.209v.968zm0-1.387h-1.629v2.875h-.744v-4h1.593c.431
            0 .78.349.78.78v.345zm5.5 2.875c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0
            .937.138 1.32.377-.53.552-.856 1.3-.856 2.123 0
            .824.326 1.571.856 2.123-.383.239-.836.377-1.32.377zm1.5-2.5c0-1.381 1.12-2.5 2.5-2.5 1.381
            0 2.5 1.119 2.5 2.5s-1.119 2.5-2.5 2.5c-1.38 0-2.5-1.119-2.5-2.5zm-8
            4.5h-3v1h3v-1zm4 0h-3v1h3v-1zm5 0h-3v1h3v-1zm4 0h-3v1h3v-1z"
        />
      );
    },
    Klarna() {
      return(
        <RenderSvg
          width="90"
          viewBow="0 0 45 24"
          svgPath="M40.7936 14.6463C40.2049 14.6463 39.7276 15.1279 39.7276 15.722C39.7276 16.316
            40.2049 16.7977 40.7936 16.7977C41.3823 16.7977 41.8597 16.316 41.8597 15.722C41.8597
            15.1278 41.3824 14.6463 40.7936 14.6463ZM37.2863 13.8146C37.2863 13.0013 36.5973
            12.342 35.7474 12.342C34.8975 12.342 34.2084 13.0013 34.2084 13.8146C34.2084 14.628
            34.8974 15.2874 35.7474 15.2874C36.5974 15.2874 37.2863 14.628 37.2863 13.8146ZM37.292
            10.9524H38.9904V16.6766H37.292V16.3108C36.8122 16.641 36.233 16.8351 35.6083 16.8351C33.9552
            16.8351 32.615 15.4828 32.615 13.8146C32.615 12.1464 33.9552 10.7942 35.6083 10.7942C36.233
            10.7942 36.8122 10.9883 37.292 11.3186V10.9524V10.9524ZM23.7
            11.6981V10.9525H21.9611V16.6766H23.7039V14.0041C23.7039 13.1024 24.6724 12.6178
            25.3444 12.6178C25.3513 12.6178 25.3577 12.6185 25.3646 12.6186V10.953C24.6749 10.953 24.0405
            11.251 23.7 11.6982L23.7 11.6981ZM19.3684 13.8147C19.3684 13.0013 18.6794 12.3421 17.8294
            12.3421C16.9795 12.3421 16.2904 13.0013 16.2904 13.8147C16.2904 14.628 16.9795 15.2875
            17.8294 15.2875C18.6794 15.2875 19.3684 14.628 19.3684 13.8147ZM19.374
            10.9525H21.0725V16.6767H19.374V16.3108C18.8942 16.641 18.315 16.8351 17.6904 16.8351C16.0372
            16.8351 14.697 15.4828 14.697 13.8146C14.697 12.1464 16.0372 10.7942 17.6904 10.7942C18.3151
            10.7942 18.8942 10.9883 19.374 11.3186V10.9524V10.9525ZM29.597 10.7985C28.9186 10.7985
            28.2764 11.011 27.8471 11.5974V10.9528H26.156V16.6767H27.8679V13.6686C27.8679 12.7981
            28.4464 12.3719 29.1429 12.3719C29.8894 12.3719 30.3185 12.8219 30.3185
            13.6568V16.6767H32.0149V13.0366C32.0149 11.7045 30.9653 10.7986 29.597 10.7986V10.7985ZM12.223
            16.6767H14.0007V8.4016H12.223V16.6767V16.6767ZM4.41394 16.679H6.29634V8.40006H4.41394V16.679ZM10.9979
            8.40006C10.9979 10.1924 10.3058 11.8597 9.07234 13.099L11.6743 16.6793H9.34934L6.52154
            12.7883L7.25135 12.2367C8.46165 11.3219 9.15585 9.92354 9.15585 8.40004H10.998L10.9979 8.40006Z"
        />
      );
    },
    PendingOrders() {
      return(
        <RenderSvg
          svgPath="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24-4v20h2v-18h18v-2h-20zm19
            14.673v-1.347l-.728-.259c-.578-.206-.858-.869-.592-1.43l.332-.697-.952-.952-.697.332
            c-.559.265-1.224-.012-1.43-.592l-.26-.728h-1.347l-.259.728c-.206.578-.87.858-1.43.592
            l-.697-.332-.952.952.332.697c.265.559-.012 1.224-.592 1.43l-.728.26v1.347l.728.259
            c.578.206.858.869.592 1.43l-.332.697.952.952.697-.332c.559-.265 1.224.012 1.43.592
            l.26.728h1.347l.257-.723c.203-.574.863-.867 1.427-.599l.702.333.952-.952-.332-.698
            c-.264-.557.01-1.223.593-1.43l.727-.258zm-5 1.161c-1.013 0-1.834-.821-1.834-1.834
            s.821-1.834 1.834-1.834 1.834.821 1.834 1.834-.821 1.834-1.834 1.834z"
        />
      );
    },
    CompletedOrders() {
      return(
        <RenderSvg
          svgPath="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24-4v20h2v-18h18v-2
            h-20zm17.625 9.996l-4.982 5.132-2.268-2.185-1.375 1.389 3.643 3.576
            6.357-6.523-1.375-1.389z"
        />
      );
    }
  }

  Media = {
    Play() {
      return(
        <RenderSvg svgPath="M2 24v-24l20 12-20 12z" />
      );
    },
    Pause() {
      return(
        <RenderSvg
          svgPath="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z"
        />
      );
    },
    Volume() {
      return(
        <RenderSvg
          svgPath="M0 19h24v-14l-24 14zm22-2h-5v-5.602l5-2.917v8.519z"
        />
      );
    },
    Messages() {
      return(
        <RenderSvg
          svgPath="M0 3v18h24v-18h-24zm21.518
            2l-9.518 7.713-9.518-7.713h19.036zm-19.518
            14v-11.817l10 8.104 10-8.104v11.817h-20z"
        />
      );
    },
    Pdf() {
      return(
        <RenderSvg
          svgPath="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6
            2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811
            13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537
            0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473
            0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619
            0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235
            0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"
        />
      );
    }
  }

  Quote = {
    Open() {
      return(
        <RenderSvg
          svgPath="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995
            2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017
            0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996
            3.638-3.996 5.849h3.983v10h-9.983z"
        />
      );
    }
  }
}

const icons = new Icons();

/*
const klarnaBase64 = `
      data:image/svg+xml;utf8;base64,
      PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/
      Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4w
      LCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBC
      dWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3Jn
      LzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcv
      MTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIw
      cHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ5Ljg2NiA0OS44NjYiIHN0
      eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Ljg2NiA0OS44
      NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhl
      aWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0xMy4yMDEs
      MTguMjY1Yy0wLjU1MywwLTEuMTA1LDAtMS42NTgsMGMtMC40MTUsMC4w
      MDEtMC40NTQsMC4wNDItMC40NTQsMC40NjJjMCwyLjAyOSwwLDQuMDU5
      LDAsNi4wODggICAgYzAsMi4wNjcsMCw0LjEzNCwwLDYuMjAzYzAsMC4z
      NDMsMC4wNTksMC40LDAuMzk3LDAuNDAyYzAuNTQyLDAuMDAzLDEuMDg1
      LDAsMS42MjksMGMwLjUxMSwwLDAuNTU4LTAuMDQ1LDAuNTU4LTAuNTQ5
      ICAgIGMwLTMuNDc4LDAtNi45NTYsMC0xMC40MzNjMC0wLjU3MiwwLjAw
      MS0xLjE0MywwLTEuNzE1QzEzLjY3MiwxOC4zMzQsMTMuNiwxOC4yNjYs
      MTMuMjAxLDE4LjI2NXoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9n
      Pgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yLjMzOSwxOC4yNjZjLTAuNTA1
      LTAuMDAzLTEuMDEtMC4wMDUtMS41MTYsMC4wMDFDMC4yOTMsMTguMjc1
      LDAsMTguNTc3LDAsMTkuMTA5YzAsMS45NjMsMCwzLjkyNywwLDUuODkg
      ICAgYzAsMS45OTMsMCwzLjk4MywwLDUuOTc2YzAsMC4zNDksMC4wNDcs
      MC4zOTYsMC4zOTcsMC4zOTZjMC41MTQsMC4wMDQsMS4wMjksMC4wMDQs
      MS41NDMsMGMwLjU0LTAuMDA0LDAuODExLTAuMjczLDAuODExLTAuODEg
      ICAgYzAtMy45NTUsMC03LjkxMiwwLTExLjg2NkMyLjc1MiwxOC4zNDgs
      Mi42NzksMTguMjY5LDIuMzM5LDE4LjI2NnoiIGZpbGw9IiMwMDAwMDAi
      Lz4KCTwvZz4KPC9nPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik05LjcyNCwz
      MS4wNzJjLTAuMTQ3LTEuNDY0LTAuNjgtMi43NjMtMS43MDUtMy44MzZj
      LTEuMTA0LTEuMTU0LTIuNDYyLTEuNzg5LTMuOTQzLTEuOTM2ICAgIGMt
      MC40NzgtMC4wMDktMC40ODItMC4wMDktMC40NjksMC4zNTJjMC4wMDUs
      MC4xMzMsMC4wMzEsMC4yNjQsMC4wNTEsMC4zOTZjMC4yNjUsMS43NTUs
      MS4xMjMsMy4xNTMsMi41NTEsNC4xOTggICAgYzAuOTM2LDAuNjg4LDEu
      OTk1LDEuMDQ1LDMuMTQ4LDEuMTUzQzkuNywzMS40MzIsOS43NTgsMzEu
      NDA4LDkuNzI0LDMxLjA3MnoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4K
      PC9nPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik05LjM2NSwxOC4yOThjLTAu
      MTMxLDAuMDIxLTAuMjY3LDAuMDEzLTAuMzk3LDAuMDMzYy0xLjUwMiww
      LjIzOS0yLjc3NywwLjkwNC0zLjc5MSwyLjA0MyAgICBjLTAuOTI5LDEu
      MDQyLTEuNDQ1LDIuMjcxLTEuNTY3LDMuNjU5Yy0wLjAzMSwwLjM2Mi0w
      LjAxMSwwLjM2MywwLjM3LDAuNDEyYzAuNTIzLTAuMTI5LDEuMDY3LTAu
      MjA4LDEuNTY3LTAuMzk4ICAgIGMyLjQ5Ny0wLjk0OCwzLjg2My0yLjc4
      OCw0LjE3OC01LjQyOEM5Ljc2NSwxOC4yODQsOS43MDksMTguMjQ2LDku
      MzY1LDE4LjI5OHoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8
      Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMi43NjMsMjQuNDk2Yy0wLjAzOC0x
      LjQ1Ny0wLjg2Ny0yLjQzMi0yLjI2OS0yLjc3N2MtMS41NTUtMC4zODYt
      My4wOTYtMC4xNi00LjYyOSwwLjE2NyAgICBjLTAuMTgyLDAuMDM4LTAu
      MjU3LDAuMTY5LTAuMjUzLDAuMzZjMC4wMDgsMC4zNDEsMC4wMDIsMC42
      ODUsMC4wMDIsMS4wMjhjMCwwLjQ4OSwwLjA2NCwwLjUxMywwLjU0OCww
      LjQ4NCAgICBjMC45ODctMC4wNTYsMS45NzYtMC4wOTgsMi45NjQtMC4w
      OThjMC41OSwwLDAuOTQ4LDAuMzMzLDEuMDQ2LDAuODY3YzAuMDMxLDAu
      MTY2LDAuMDE5LDAuMzQyLDAuMDE5LDAuNTEzICAgIGMwLjAwMiwwLjY2
      MywwLjAwMSwwLjY2OS0wLjY1MywwLjY2MWMtMC45MTQtMC4wMDktMS44
      MjUsMC4wMjEtMi43MSwwLjI4OWMtMC45MTQsMC4yNzQtMS41NzEsMC44
      MTUtMS43NjksMS43ODkgICAgYy0wLjEyNywwLjYyOS0wLjExNCwxLjI2
      NCwwLjAzOSwxLjg5NmMwLjIxMiwwLjg3NSwwLjc0MiwxLjQ3MSwxLjYw
      OCwxLjcyOWMwLjk2MiwwLjI4MywxLjkxNSwwLjI0MiwyLjgzLTAuMjAx
      ICAgIGMwLjI2Mi0wLjEyNywwLjUxMS0wLjI4MywwLjgwNC0wLjQ0NmMw
      LjAxMiwwLjA5MSwwLjAyMSwwLjEzNywwLjAyMywwLjE4NGMwLjAyMiww
      LjQzLDAuMDc2LDAuNDg0LDAuNTEyLDAuNDg0ICAgIGMwLjUxNCwwLjAw
      MSwxLjAyOCwwLjAwMiwxLjU0MywwYzAuMjk5LTAuMDAyLDAuNDA1LTAu
      MDk3LDAuNDA0LTAuMzg4QzIyLjgwOSwyOC44NTYsMjIuODIsMjYuNjc0
      LDIyLjc2MywyNC40OTZ6IE0xOS45MzgsMjkuNDA4ICAgIGMtMC41Niww
      LjIxNy0xLjEyNiwwLjMzNC0xLjcyNywwLjIzYy0wLjM3NC0wLjA2NC0w
      LjY0OC0wLjI4Ni0wLjY4LTAuNjY2Yy0wLjAyOC0wLjM0Ny0wLjAwOC0w
      LjcwNSwwLjA2LTEuMDQ0ICAgIGMwLjA3My0wLjM2MiwwLjMzOS0wLjYy
      NywwLjcwMi0wLjY3M2MwLjU5Mi0wLjA3MywxLjE5MS0wLjA4OSwxLjc4
      Ny0wLjExYzAuMDUzLTAuMDAyLDAuMTU0LDAuMTI3LDAuMTU5LDAuMjAx
      ICAgIGMwLjAxOSwwLjI5NCwwLjAwOCwwLjU5LDAuMDA4LDAuODg1YzAs
      MC4yMy0wLjAxOCwwLjQ2LDAuMDA1LDAuNjg3QzIwLjI4MiwyOS4xODIs
      MjAuMTc5LDI5LjMxNiwxOS45MzgsMjkuNDA4eiIgZmlsbD0iIzAwMDAw
      MCIvPgoJPC9nPgo8L2c+CjxnPgoJPGc+CgkJPHBvbHlnb24gcG9pbnRz
      PSI0OC41OTIsMjAuOTY4IDQ4LjU5MiwyMS4wNDMgNDguODAyLDIxLjA0
      MyA0OC44MDIsMjEuNjU2IDQ4Ljg5MiwyMS42NTYgNDguODkyLDIxLjA0
      MyA0OS4xMDIsMjEuMDQzICAgICA0OS4xMDIsMjAuOTY4ICAgIiBmaWxs
      PSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+Cgk8Zz4KCQk8cGF0aCBk
      PSJNNDkuODIzLDIwLjk2OGgtMC4xMTlsLTAuMTIzLDAuMzMzYy0wLjAy
      OSwwLjA4Ny0wLjA1NSwwLjE2Mi0wLjA3MiwwLjIzMmgtMC4wMDFjLTAu
      MDIxLTAuMDcyLTAuMDQyLTAuMTQ4LTAuMDctMC4yMzIgICAgbC0wLjEx
      Ny0wLjMzM2gtMC4xMTNsLTAuMDQ3LDAuNjg4aDAuMDg0bDAuMDItMC4y
      OTZjMC4wMDYtMC4xMDQsMC4wMTEtMC4yMTksMC4wMTMtMC4zMDRoMC4w
      MDIgICAgYzAuMDIxLDAuMDgxLDAuMDQ3LDAuMTcsMC4wNzksMC4yNjds
      MC4xMTIsMC4zMjloMC4wNjdsMC4xMjEtMC4zMzVjMC4wMzUtMC4wOTUs
      MC4wNjItMC4xODEsMC4wODYtMC4yNjFoMC4wMDIgICAgYzAsMC4wODQs
      MC4wMDYsMC4yMDIsMC4wMTMsMC4yOTdsMC4wMTYsMC4zMDNoMC4wOUw0
      OS44MjMsMjAuOTY4eiIgZmlsbD0iIzAwMDAwMCIvPgoJPC9nPgo8L2c+
      CjxnPgoJPGc+CgkJPHBhdGggZD0iTTM5LjExNSwyNS4xNDZjLTAuMDA2
      LTAuNTItMC4wNjctMS4wNDYtMC4xOC0xLjU1N2MtMC4yMjUtMS4wNTUt
      MC44My0xLjc5OC0xLjk0MS0xLjk5MiAgICBjLTEuMjEzLTAuMjE1LTIu
      MzY5LTAuMDQzLTMuNDEsMC42NjNjLTAuMTAyLDAuMDY4LTAuMjA0LDAu
      MTMtMC4zNzksMC4yNDFjLTAuMDA4LTAuMjA1LTAuMDItMC4zMzItMC4w
      MjItMC40NjEgICAgYy0wLjAwOC0wLjMzNi0wLjA2Ni0wLjM5OS0wLjQw
      NC0wLjQwMWMtMC41MzctMC4wMDMtMS4wNjktMC4wMDQtMS42MDIsMC4w
      MDFjLTAuMzE0LDAuMDAyLTAuNDA4LDAuMS0wLjQwOCwwLjQxMyAgICBj
      LTAuMDAxLDIuOTczLTAuMDAxLDUuOTQ1LDAsOC45MThjMCwwLjM0OSww
      LjA5NCwwLjQ0NSwwLjQzNiwwLjQ0N2MwLjU5MSwwLjAwMiwxLjE4Mi0w
      LjAwNiwxLjc3MywwLjAwNSAgICBjMC4yNjQsMC4wMDMsMC4zODMtMC4x
      MDEsMC4zNzgtMC4zNzJjLTAuMDEtMC41OS0wLjAwMy0xLjE4LTAuMDAz
      LTEuNzcyYzAtMS41NzItMC4wMDMtMy4xNDQsMC4wMDYtNC43MTYgICAg
      YzAtMC4xMywwLjAzNC0wLjMyNSwwLjEyMS0wLjM3OWMwLjY1Ni0wLjQw
      NCwxLjM2LTAuNjUyLDIuMTQzLTAuNTIyYzAuNDMxLDAuMDcxLDAuNzMz
      LDAuMzI0LDAuODE0LDAuNzY5ICAgIGMwLjA1NywwLjMwOCwwLjEsMC42
      MjEsMC4xLDAuOTMzYzAuMDEyLDEuODY5LDAuMDA2LDMuNzM2LDAuMDA2
      LDUuNjA0YzAsMC40MDcsMC4wNDcsMC40NTIsMC40NjQsMC40NTIgICAg
      YzAuNTM0LDAuMDAzLDEuMDY5LDAuMDAzLDEuNjAyLDBjMC40NjEsMCww
      LjUxNC0wLjA1MSwwLjUxNC0wLjUwMkMzOS4xMjMsMjguOTk2LDM5LjEz
      NiwyNy4wNzIsMzkuMTE1LDI1LjE0NnoiIGZpbGw9IiMwMDAwMDAiLz4K
      CTwvZz4KPC9nPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00OC4yMTYsMjUu
      MDM1Yy0wLjAwMS0wLjI5My0wLjAxNS0wLjU5LTAuMDYtMC44ODRjLTAu
      MTc4LTEuMTQtMC43ODktMS45NTQtMS44OTYtMi4zMjMgICAgYy0wLjQ2
      NC0wLjE1NS0wLjk1OS0wLjI2Mi0xLjQ0Ni0wLjI5M2MtMS4xNjQtMC4w
      NzMtMi4zMTQsMC4wNjYtMy40NTMsMC4zMjNjLTAuMjUsMC4wNTgtMC4z
      NTUsMC4xOTQtMC4zNTQsMC40MzggICAgYzAuMDAxLDAuMzE0LDAsMC42
      MjksMCwwLjk0M2MwLDAuNTA2LDAuMDcsMC41NDUsMC41NjIsMC41MTlj
      MC45OC0wLjA1NSwxLjk1OS0wLjA5OSwyLjkzOC0wLjEwNSAgICBjMC42
      MzItMC4wMDIsMC45ODYsMC4zMjMsMS4wOTMsMC45NDhjMC4wNDgsMC4y
      OCwwLjAyMiwwLjU3MSwwLjAzNywwLjg1NGMwLjAxLDAuMTg4LTAuMDc5
      LDAuMjUtMC4yNTksMC4yNDQgICAgYy0wLjQxLTAuMDA2LTAuODI1LTAu
      MDQxLTEuMjI5LDAuMDA4Yy0wLjY5NSwwLjA4Mi0xLjQsMC4xNjItMi4w
      NzIsMC4zNWMtMC44OTEsMC4yNTEtMS40NjksMC44NzQtMS42MTUsMS44
      MTIgICAgYy0wLjA1OSwwLjM4OS0wLjA0OSwwLjc5My0wLjAzLDEuMTg5
      YzAuMDc2LDEuNjA4LDEuMzAzLDIuNjY0LDIuOTA4LDIuNTM0YzAuODEx
      LTAuMDYzLDEuNTc1LTAuMjU2LDIuMjM5LTAuNzUxICAgIGMwLjA0NS0w
      LjAzMiwwLjEwMi0wLjA1LDAuMTY0LTAuMDc4YzAuMDI5LDAuMDQ0LDAu
      MDQ3LDAuMDU1LDAuMDQ5LDAuMDczYzAuMDEsMC4wNDgsMC4wMTUsMC4w
      OTUsMC4wMTcsMC4xNDQgICAgYzAuMDIyLDAuNDE1LDAuMDQ5LDAuNDQs
      MC40NzUsMC40NGMwLjQ5NiwwLjAwMywwLjk5LDAuMDAzLDEuNDg2LDBj
      MC4zOTksMCwwLjQ0OC0wLjA0NSwwLjQ0OC0wLjQzOCAgICBDNDguMjE5
      LDI5LDQ4LjIyMSwyNy4wMTcsNDguMjE2LDI1LjAzNXogTTQ1LjY5Nywy
      OS4wMDljMC4wMDcsMC4xODItMC4wNzEsMC4yNzgtMC4yMywwLjM0OSAg
      ICBjLTAuNTg1LDAuMjU5LTEuMTg2LDAuMzkyLTEuODI5LDAuMjg3Yy0w
      LjQ2My0wLjA3OS0wLjczNC0wLjM5My0wLjcyNi0wLjk3NWMwLjAxNS0w
      Ljk4OSwwLjIzMy0xLjQzNiwxLjQzNi0xLjUyNyAgICBjMC4zNTktMC4w
      MjgsMC43MjQsMC4wMDEsMS4wODUtMC4wMDdjMC4yMDMtMC4wMDksMC4y
      NjgsMC4wODYsMC4yNjQsMC4yNzRjLTAuMDA5LDAuMjc2LTAuMDA0LDAu
      NTU0LTAuMDA0LDAuODI4ICAgIEM0NS42OTUsMjguNDk0LDQ1LjY4NSwy
      OC43NSw0NS42OTcsMjkuMDA5eiIgZmlsbD0iIzAwMDAwMCIvPgoJPC9n
      Pgo8L2c+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTI5LjY5MiwyMS44Nzdj
      MC4wMDEtMC4yNTUtMC4xMTMtMC4zNjQtMC4zNjYtMC4zNTRjLTAuNzQ0
      LDAuMDMyLTEuNDE2LDAuMjY2LTIuMDE4LDAuNzAxICAgIGMtMC4wODIs
      MC4wNi0wLjE2NCwwLjExNS0wLjI4NywwLjIwM0MyNy4wMTEsMjIuMjks
      MjcsMjIuMjA5LDI3LDIyLjEyN2MtMC4wMDgtMC40MS0wLjA4LTAuNDg2
      LTAuNDgxLTAuNDg2ICAgIGMtMC41MTctMC4wMDItMS4wMjktMC4wMDEt
      MS41NDQtMC4wMDFjLTAuNDcxLDAuMDAxLTAuNTQ5LDAuMDc3LTAuNTQ5
      LDAuNTQ2YzAsMS4zMTQsMCwyLjYzLDAsMy45NDRjMCwxLjU5NC0wLjAw
      MSwzLjE4NSwwLDQuNzc0ICAgIGMwLDAuNDIyLDAuMDkzLDAuNTE2LDAu
      NDk0LDAuNTE2YzAuNTQyLDAuMDAzLDEuMDg3LDAuMDAzLDEuNjMsMGMw
      LjQzOCwwLDAuNTA4LTAuMDY2LDAuNTA4LTAuNTE4ICAgIGMwLjAwMS0y
      LjExMywwLjAwMy00LjIyOS0wLjAwNC02LjM0N2MwLTAuMjA5LDAuMDYx
      LTAuMzI4LDAuMjUzLTAuNDE5YzAuNjM2LTAuMjk5LDEuMjg4LTAuNTIz
      LDIuMDAzLTAuNDk0ICAgIGMwLjI2MiwwLjAxMSwwLjM4OS0wLjA4OCww
      LjM4My0wLjM2NUMyOS42ODIsMjIuODA5LDI5LjY4NCwyMi4zNDQsMjku
      NjkyLDIxLjg3N3oiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8
      Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9n
      Pgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4K
      PC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8
      Zz4KPC9nPgo8L3N2Zz4K
    `;

icons.Shop.Klarna = () => {
  return <img alt="Pay with Klarna" src={klarnaBase64} />;
}
*/

export default icons;
