import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

//TODO: rename ButtonGroup?
export const ButtonGroup = ({ style, children }) => (
  <div className="button-container-outer" style={style}>
    <div className="button-container-inner">
      {children}
    </div>
  </div>
);

ButtonGroup.propTypes = {
  children: PropTypes.node,
}

export const Button = ({ onClick, text, ariaLabel, theme, className }) => (
  <div className={`button-wrapper ${theme ? theme : ''} ${className}`}>
    <button onClick={onClick} aria-label={ariaLabel}>
      {text}
    </button>
  </div>
);

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  ariaLabel: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  text: 'Click me',
}

export const Toggle = ({ caption, style, onToggle, flag }) => (
  <div className={`toggle-wrapper ${flag ? 'toggled' : ''}`}>
    <div className="toggle">
      <input type="checkbox" onChange={onToggle}/>
      <label></label>
    </div>
    <div className="caption" style={style}>{caption}</div>
  </div>
);

Toggle.propTypes = {
  caption: PropTypes.string,
  style: PropTypes.object,
  onToggle: PropTypes.func,
  flag: PropTypes.bool.isRequired,
}

export const Checkbox = ({ id, caption }) => (
  <div className="checkbox-wrapper">
    <input
      type="checkbox"
      id={id}
      name={id}
      value={id}
    />
    <span>{caption}</span>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
}
