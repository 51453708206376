import validate from './shop.validate';

class TokenStorage {
  constructor() {
    this.namespace = 'STOA_session_token';
  }

  /**
   * @returns {String} String of session token in localStorage
   */
  get() {
    return window.localStorage[this.namespace];
  }

  /**
   * @param {String} content - string containing token
   */
  set(content) {
    if(validate.isString(content)) {
      window.localStorage.setItem(this.namespace, content);
    } else {
      throw new Error(
        'The content has to be a string'
      );
    }
  }

	unset() {
    window.localStorage.setItem(this.namespace, '');
  }
  
  /**
   * Initializes the localStorage with an empty string
   */
  __init() {
    if(!this.get()) {
      this.set('');
    }
  }
}

const tokenStorage = new TokenStorage();
tokenStorage.__init();

export default tokenStorage;
