import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import validate from '../../../utilities/js/shop.validate';
import tokenStorage from '../../../utilities/js/shop.tokenStorage';
import cartStorage from '../../../utilities/js/shop.cartStorage';
import { CartContext } from '../../../context';
import { TextContentConsumer } from '../../../utilities/js/text-content-management';
import isProduction from '../../../utilities/js/is-production';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import AmazonPay from '../../payments/amazon/amazon-pay/amazon-pay';
import SmoothScroll from '../../../utilities/js/smooth-scroll';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ButtonGroup, Button, Toggle } from '../../building-blocks/button/button';
import CartContent from '../cart-content/cart-content';
import './shopping-modal.css';

//TODO: fetch config from dot env or server
const directPaymentsConfig = {
  env: isProduction ? 'production' : 'sandbox',
  currency: 'EUR',
  locale: 'de_DE',
  client: {
    paypal: {
      sandbox: 'ARZGM1fAFTJn2GzznlhxSN-uv7EcMDU9jRXXOv6_Yg16anrRCPli6x8WBneEMMGw3hgnLJCGJj9Knp0D',
      production: 'AUikxAokgomunu-rGMRDpNx3Jimdv9s7gUG59K-mfMFmYV4OwdYOpR0Yl4sGAAmN0CJcjmYQM8xcuQLI',
    },
    amazon: {
      merchantID: 'A3VYYJUZF9OWCF',
      clientID: 'amzn1.application-oa2-client.9329dbb6112a491b9e57fcaae828c6f5',
      route: '/#/amazon',
    }
  }
}

const styPaypalExpress = {
  color: 'white',
  shape: 'pill',
  size: 'small',
  height: 50,
  label: 'checkout',
  tagline: false,
}

export default function ShoppingModal({ active, toggleHandler, closeHandler, syncCartContext }) {
  const [isLegalAccepted, setIsLegalAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const smoothScroll = useRef(null);
  const history = useHistory();

  const sessionToken = tokenStorage.get();

  const handleLegalToggle = () => {
    setIsLegalAccepted(state => !state);
  }

  const handleProceed = () => {
    if(isLegalAccepted) {
      closeHandler();
      history.push(`/address?sessionToken=${sessionToken}`);
    }
  }

  const handleSuccess = () => {
    console.log('The payment was successfull!');
    history.push(`/order-completed?sessionToken=${sessionToken}`);
    cartStorage.clear();
    syncCartContext();
    toggleHandler();
    setIsOpen(false);
    setIsLegalAccepted(false);
  }

  const handleCancel = () => {
    alert('Der Bezahlvorgang wurde abgebrochen.');
  }

  const handleError = (error) => {
    console.error('Error while finalising the order!', error);
    history.push('/order-error');
  }

  const handleLogin = () => {}

  useEffect(() => {
    setIsOpen(active);
    
    smoothScroll.current = new SmoothScroll({
      container: document.getElementById('app-content')
    }).scroll;

    if(!active) {
      setIsLegalAccepted(false);
    }
  }, [active]);

  const checkoutHandlers = {
    onLogin: handleLogin,
    onSuccess: handleSuccess,
    onCancel: handleCancel,
    onError: handleError,
  };

  return(
    <Modal
      isOpen={isOpen}
      toggle={toggleHandler}
      className="shopping-modal"
    >
      <CartContext.Consumer>
        {({ cartContextData, syncCartContext }) => (
          <TextContentConsumer>
            {({ commerce, notifications }) => (
              <>
                <ModalHeader>
                  {commerce.cart}
                </ModalHeader>
                <ModalBody className="shopping-modal-body">
                  <CartContent
                    articles={cartContextData.cart}
                    netValue={cartContextData.netValue}
                    closeHandler={closeHandler}
                    syncHandler={syncCartContext}
                  />
                </ModalBody>
                <ModalFooter className="shopping-modal-footer">
                  {!validate.isEmptyCart() &&
                    <Toggle
                      caption={notifications.acceptLegal}
                      style={{ fontSize: '.8rem' }}
                      onToggle={handleLegalToggle}
                      flag={isLegalAccepted && !validate.isEmptyCart()}
                    />
                  }
                  <ButtonGroup>
                    <Button
                      theme="light"
                      onClick={closeHandler}
                      text="Schließen"
                      ariaLabel="Schließen"
                    />
                    <Button
                      className={isLegalAccepted && !validate.isEmptyCart() ? '' : 'disabled'}
                      theme="dark"
                      onClick={handleProceed}
                      text="Zur Kasse"
                      ariaLabel="Zur Kasse"
                    />
                    {!validate.isEmptyCart() &&
                      <div className="direct-payments">
                        <div className="direct-payments__caption">
                          {/*TODO (when Amazon pay ready): change to
                            'Oder verwenden Sie Ihre Adress- und Bezahldaten der folgenden Dienste:'
                          */}
                          Oder verwenden Sie Ihre Adress- und Bezahldaten von PayPal:
                        </div>
                        <div className={`direct-payments__action ${isLegalAccepted ? '' : 'disabled'}`}>
                          {false &&
                            <div className="direct-payments__action__amazon">
                              <AmazonPay
                                config={directPaymentsConfig.client.amazon}
                                onLogin={checkoutHandlers.onLogin}
                              />
                            </div>
                          }
                          <div className="direct-payments__action__paypal">
                            <PaypalExpressBtn
                              env={directPaymentsConfig.env}
                              client={directPaymentsConfig.client.paypal}
                              currency={directPaymentsConfig.currency}
                              total={Number(cartContextData.valueFinal.toFixed(2))}
                              onSuccess={checkoutHandlers.onSuccess}
                              onError={checkoutHandlers.onError}
                              onCancel={checkoutHandlers.onCancel}
                              style={styPaypalExpress}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </ButtonGroup>
                </ModalFooter>
              </>
            )}
          </TextContentConsumer>
        )}
      </CartContext.Consumer>
    </Modal>
  );
}

ShoppingModal.propTypes = {
  active: PropTypes.bool,
  toggleHandler: PropTypes.func,
  closeHandler: PropTypes.func,
  syncCartContext: PropTypes.func,
}

ShoppingModal.defaultProps = {
  active: false,
  toggleHandler: () => {},
  closeHandler: () => {},
  syncCartContext: () => {},
}
