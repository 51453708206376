import { Component } from 'react';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
  constructor() {
    super();
    this.state = {
      containerNode: document.documentElement,
      scrollHandler: null,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps !== this.props) {
      this.setState(this.props);
    }
    
    if(this.props.location.pathname !== prevProps.location.pathname) {
      const { containerNode, scrollHandler } = this.state;
      if(scrollHandler) {
        scrollHandler(0);
      } else if(containerNode) {
        containerNode.scrollTop = 0;
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
