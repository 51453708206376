import axios from 'axios';
import isProduction from './is-production';

//const catalogueLocal = require('../../../data/catalogue/catalogue_stoa.json');
//const catalogueFallback = JSON.parse(catalogueLocal);
const catalogueSrc = 'https://www.stoa.berlin/data/catalogue/catalogue_stoa.json';

const fetchCatalogue = async () => {
  if(isProduction) {
    const response = await axios(catalogueSrc);
    return await response.data;
  } else {
    return Promise.resolve(catalogueFallback);
  }
}

export default fetchCatalogue;

const catalogueFallback = {
  audio: [
    {
      id: "SCD-251",
      slug: "midnight-apple",
      title: "Midnight Apple",
      author: "Clemens Grassmann",
      released: "2020-02-01",
      price: "11.99",
      details: [
        "Der Schlagzeuger und Komponist Clemens Grassmann lässt sich von verschiedenen, aber zusammenhängenden Einflüssen inspirieren - Vergangenheit und Gegenwart. Seine zweite Veröffentlichung Midnight Apple etabliert als musikalisches Leitmotiv etliche Facetten des Dualismus, um verschiedene Ausdrucksmöglichleiten innerhalb seiner Musik zu erforschen und offenzulegen. Die musikalischen Parameter der harmonischen Spannung und Auflösung, das Ein- und Ausatmen der Triolen und natürlich die Verzweigung und Vorwärtsbewegung der Musik sind hierbei von elementarer Bedeutung. „Das gesamte Album verbindet Tradition mit modernen Einflüssen“, sagt Clemens, dessen Kompositionen auf Midnight Apple viel Freiraum für duales Experimentieren bilden.",
        "Um jene aufgezeigten Potenziale ausschöpfen zu können, vereint Clemens ein Ensemble von Künstlern, die sich mit der Interpretation seiner musikalischen Erzählungen bestens auskennen: Jernej Bervar und Yoav Eshed an den Gitarren, David Milazzo am Altsaxophon, Alex Madeline am Tenorsaxophon und Van Lier Fellow Nick Dunston am Bass.",
        "Der Dualismus - und die Idee des komplementären Soundscaping - wird während der gesamten Aufnahme als Thema aufgegriffen auf und schafft somit viel Raum für duale Interaktion. Clemens Kompositionen zeigen das Zusammenspiel von zwei Saxophonen, zwei Gitarren, Bass und Schlagzeug. Während eine Stimme eine Grundfläche aus dynamischen Bewegungen, langen Tönen und Pads bietet, reagiert die andere aktiv und greift auf rhythmische Figuren und scharfe Melodien zurück. Diese Hommage aus dem Jahr 2019 an die Call-and-Response-Tradition ist von einem Teilabschnitt zum nächsten ungebrochen. „Mein Ziel war es, mit der Instrumentierung ein neues Klangerlebnis zu schaffen“, sagt Clemens, „aus der Tradition heraus eine musikalische Weiterentwicklung zu erschaffen, um sich klanglich bewusst von allem zuvor Gehörten abzusetzen.“",
        "Diese Verbindungen zeigen sich von Stück zu Stück unterschiedlich. Für den Titeltrack des Albums lässt sich Clemens buchstäblich von Ahmad Jamals „Poinciana“ inspirieren, um dann sengende, verzerrte Gitarrenlinien als Interpretationsspielraum zum Experimentieren mit zeitgenössischem Jazzvokabular und Effekten miteinzubeziehen.",
        "Wie inspirativ sich eine ungemein intensive Trainingseinheit im Fitnesstudio auf die Musik auswirken kann wird durch den sportlichen Puls für „On the Treadmill“ offenbart. Die Melodie verwirklicht sich mit einer immensen Energie, die sich im Tempo der Komposition widerspiegelt und in diesem Fall sogar perfekt über eine Rhythm Changes form passt, welche mit ihrer zirkulären Repetition an ein Laufband erinnert und dem Ensemble somit die Möglichkeit bietet, ihre narrative Entwicklung - und den Individualismus als Solisten - über eine klassische Songform zum Ausdruck zu bringen.",
        "Clemens enthüllt in „Sphere“ harmonischen und strukturellen Dualismus und setzt Kontrapunkte für die Saxophone gegen ätherische Gitarreneffekte. Bewegt von einer historischen Saga einer einzelnen Rakete, die die Erdatmosphäre verlässt und in den Weltraum eintritt, um sowohl die Einheit als auch die Unendlichkeit des Universums im Einklang zu erforschen, schrieb er eine Reihe täuschend ähnlicher Melodien mit jeweils unterschiedlicher Auflösung. Um diese Reise jener abtrünnigen Rakete, die von einem Neutronensturm getroffen wurde, wiederaufzufangen, geht Clemens in eine 9/8-Sektion über, die in die pentatonische Harmonie hinein- und aus ihr herausrutscht - ein musikalischer Kommentar zu seiner eigenen Verbindung mit der Vielfältigkeit der Musik. „Die Rakete wird von verschiedenen Partikeln und Neutrinos getroffen - verschiedenen Einflüssen - und aus der Bahn geworfen, als sie die Erde verlässt“, sagt er, „aber dennoch behält sie eine Art vertraulichen Kurs in unerforschte Gebiete bei.“",
        "Die gesamte Aufnahme lässt sich auf die Wurzeln der Musik und viele ihrer mannigfaltigen Entwicklungen zurückführen - Raum lassen, um sich in verschiedene Richtungen omnipräsent auszudehnen. Dualismus und Fortdauernde Beziehungen - musikalisch und philosophisch - helfen Clemens, eine intime und ausdrucksstarke Erzählung durch Midnight Apple zu weben. Weitere Informationen und eine Vorschau der einzelnen Titel finden Sie unter <a href='https://www.clemensgrassmann.com/' target='_blank'>clemensgrassmann.com</a>",
        "<i>Track listing:</i> 1)&nbsp;Midnight Apple 2)&nbsp;Zig Zag 3)&nbsp;On The Treadmill 4)&nbsp;Sphere 5)&nbsp;The Beat Zapps 6)&nbsp;Mystic",
        "<i>Lineup:</i> David Milazzo (as), Alex Madeline (ts), Jernej Bervar (git), Yoav Eshed (git), Nick Dunston (b), Clemens Grassmann (dr)"
      ],
      img: {
        "shelf": "https://stoa.berlin/data/catalogue/assets/audio/midnightapple/midnightapple-shelf.jpg",
        "details": "https://stoa.berlin/data/catalogue/assets/audio/midnightapple/midnightapple-details.png"
      },
      demos: [
        {
          id: "cg_midnight_apple",
          artist: "Clemens Grassmann",
          title: "Midnight Apple",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/midnightapple/midnightapple-midnight_apple.mp3",
              type: "audio/mp3"
            }
          ]
        },
        {
          id: "cg_zig_zag",
          artist: "Clemens Grassmann",
          title: "Zig Zag",
          sources: [
            {
              "api": "stoa",
              "src": "https://stoa.berlin/data/catalogue/assets/audio/midnightapple/midnightapple-zig_zag.mp3",
              "type": "audio/mp3"
            }
          ]
        }
      ]
    },
    {
      id: "SCD-229",
      slug: "fragment",
      title: "fragment",
      author: "ruben giannotti_jazz orchestra",
      released: "2019-10-01",
      price: "16.99",
      details: [
        "Das hochkarätig besetzte Ensemble wurde 2019 von Ruben Giannotti ins Leben gerufen. Im Zentrum der Debut-Produktion steht die <i>fragment</i> Suite für Big Band, in der sich der Komponist intensiv mit Perspektivwechseln und der Beziehung zwischen der elektronischen und händischen instrumentalen Tonerzeugung beschäftigt hat. Das Resultat ist ein organisches Gemenge aus klassischen Big-Band-Topoi, Beats, Samples und generativen Automaten, die sich in jeder Schicht entfalten und nicht eineindeutig auf bestimmte Bereiche beschränkt werden.",
        "<i>Track listing:</i> 1)&nbsp;fragment #1 2)&nbsp;fragment #2 3)&nbsp;fragment#3 4)&nbsp;serpent 5)&nbsp;ruine 6)&nbsp;vaermeland 7)&nbsp;Ana Maria",
        "<i>Lineup:</i> Philipp Gerschlauer (ss, as, cl, fl), Eldar Tsalikov (as, cl, fl), Peter Ehwald (ts, cl), Finn Wiesner (ts, cl, fl), Tini Thomsen (bs, bcl), Tobias Weidinger (tp, flg), Nicolas Boysen (tp, flg), Johannes Böhmer (tp, flg), Florian Menzel (tp, flg), Simon Harrer (trb), Janning Trumann (trb), Johannes Lauer (trb), Felix Konradt (b-trb), Maria Baptist (pno, rhodes), Attila Muehl (git), Matthias Eichhorn (b), Fabian Rösch (dr), Lars Seniuk (cond)",
        "Mehr Informationen zur Band finden Sie auf <a href='https://www.rubengiannotti.com/jazz-orchestra.html/' target='_blank'>rubengiannotti.com</a>."
      ],
      img: {
        "shelf": "https://stoa.berlin/data/catalogue/assets/audio/fragment/fragment-shelf.png",
        "details": "https://stoa.berlin/data/catalogue/assets/audio/fragment/fragment-details.png"
      },
      demos: [
        {
          id: "rgjo_fragment3",
          artist: "ruben giannotti_jazz orchestra",
          title: "fragment #3",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/fragment/fragment-fragment3.mp3",
              type: "audio/mp3"
            }
          ]
        },
        {
          id: "rgjo_serpent",
          artist: "ruben giannotti_jazz orchestra",
          title: "serpent",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/fragment/fragment-serpent.mp3",
              type: "audio/mp3"
            }
          ]
        }
      ]
    },
    {
      id: "SCD-1110",
      slug: "no-greater-love",
      title: "No Greater Love",
      author: "Anna Margolina",
      released: "2017-06-01",
      price: "9.99",
      details: [
        "Wo Jazz draufsteht, ist nicht nur Jazz drinnen: die Berliner Band um die Sängerin Anna Margolina arrangiert, interpretiert und improvisiert Songs verschiedenster Genres und Kulturen - und lässt sie überraschend neu erklingen. Auf dieser Live-EP sind Amerikanische Jazz Standards der 1930er und 1940er, tanzbarer Swing und ein Jiddisches Lied zu hören, aufgenommen und produziert bei STOA records.",
        "<i>Track listing:</i> 1)&nbsp;No Moon At All 2)&nbsp;Dream A Little Dream Of Me 3)&nbsp;Bernies Tune 4)&nbsp;The Nearness Of You 5)&nbsp;Cheek To Cheek 6)&nbsp;Papirosen 7)&nbsp;Too Darn Hot",
        "<i>Lineup:</i> Anna Margolina (voc), Patrick Hamacher (ts), Ruben Giannotti (tp), Benjamin Geyer (pno), Hendrik Nehls (bass), Bernd Ratmeyer (dr)",
        "Mehr Informationen zur Band finden Sie auf <a href='https://www.margolinamusic.com/' target='_blank'>margolinamusic.com</a>."
      ],
      img: {
        shelf: "https://stoa.berlin/data/catalogue/assets/audio/nogreaterlove/nogreaterlove-shelf.png",
        details: "https://stoa.berlin/data/catalogue/assets/audio/nogreaterlove/nogreaterlove-details.jpg"
      },
      demos: [
        {
          id: "am_toodarnhot",
          artist: "Anna Margolina",
          title: "Too Darn Hot",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/nogreaterlove/nogreaterlove-too_darn_hot.mp3",
              type: "audio/mp3"
            }
          ]
        },
        {
          id: "am_papirosen",
          artist: "Anna Margolina",
          title: "Papirosen",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/nogreaterlove/nogreaterlove-papirosen.mp3",
              type: "audio/mp3"
            }
          ]
        }
      ]
    },
    {
      id: "SCD-1",
      slug: "von-freunden",
      title: "Von Freunden",
      author: "INSUL",
      released: "2016-11-01",
      price: "14.99",
      details: [
        "Auf einer einsamen Insel einfach nur Musik machen.",
        "Gelegentlich überkommt einen diese von Fernweh geprägte Vorstellung und man verfällt ins Träumen. Dabei haben sich vor etwa drei Jahren auch die vier Musikern von INSUL kennengelernt. Seitdem verbringen sie jedoch die meiste Zeit in Berlin und Leipzig und schätzen daran das, was auf keiner einsamen Insel zu finden ist: eine Vielfalt von Menschen in ihrer Umgebung.",
        "Ihr Debütalbum „von Freunden“ handelt von eben diesen verschiedenen inspirierenden Personen und Gegebenheiten mit ihnen. Die Melodien entstehen dabei aus spontanen Impulsen und werden immer weiter verfeinert, die Improvisationen dienen zum genaueren Zeichnen der Charakterzüge. Mit dem Moment jedes erklungenen Tones ändern sich die Bilder der Menschen – mit der Entwicklung der Musiker ihr Blick auf sie.",
        "Während sich die so entstandenen Stücke des Gitarristen Bertram Burkert durch einen stetig zirkulierenden Puls auszeichnen, gehen die Kompositionen Jonas Timms nur langsam auf, tanzen dann aber manchmal hinaus in die Welt. Anders sein Teilzeitverwandter Fabian Timm, dessen Werke so anmuten als wären es Schilderungen eines Beobachters am Fenster, die getriebene Welt draußen beschreibend. Geerdet gibt Schlagzeuger Lukas Akintaya durch seine klaren, zusätzlich aber harmonisch unkonventionellen Themen eine wichtige Zutat hinzu.",
        "Trotz dieses Reichtums an Spektren liegt der Schwerpunkt auf dem Wohlklang der vier Instrumente miteinander. Gemeinsam lassen sie Komplexes vertraut klingen und malen Einfachheiten farbenfroh aus.",
        "Die Vier würden, da manchmal auch sie sehr gute Freunde sind, behaupten, sich einige Zeit auf einer dann viersamen Insel auszuhalten – zumindest ohne Instrumente.",
        "<i>Track listing:</i> 1)&nbsp;Conflux 2)&nbsp;Kathis Aktionismus 3)&nbsp;Qigong Kugeln 4)&nbsp;Die Kleine Miriam 5)&nbsp;Ein Alter Mann 6)&nbsp;86 Steps 7)&nbsp;Amsterdam 8)&nbsp;Von Freunden",
        "<i>Lineup:</i> Bertram Burkert (git), Jonas Timm (pno), Fabian Timm (bass), Lukas Akintaya (dr)"
      ],
      img: {
        shelf: "https://stoa.berlin/data/catalogue/assets/audio/vonfreunden/vonfreunden-shelf.png",
        details: "https://stoa.berlin/data/catalogue/assets/audio/vonfreunden/vonfreunden-details.png"
      },
      demos: [
        {
          id: "insul_conflux",
          artist: "INSUL",
          title: "Conflux",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/vonfreunden/vonfreunden-conflux.mp3",
              type: "audio/mp3"
            }
          ]
        },
        {
          id: "insul_86steps",
          artist: "INSUL",
          title: "86 Steps",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/vonfreunden/vonfreunden-86_steps.mp3",
              type: "audio/mp3"
            }
          ]
        }
      ]
    },
    {
      id: "SCD-0",
      slug: "planeten",
      title: "Planeten",
      author: "orpheusIII",
      released: "2016-09-22",
      price: "14.99",
      details: [
        "planeten handelt von den olympischen Göttern - die Themen für die einzelnen Stücke entstammen aus der gleichnamigen Suite für Quintett, komponiert von Ruben Giannotti.",
        "<i>ares - aphrodite - hermes - zeus - kronos - uranos - poseidon</i>",
        "Jeder Satz der Suite stellt eine Gottheit dar, mit all ihren Eigenschaften und Eigenarten. In der Abfolge der Sätze stehen sich dabei die Dichotomien ARES (Symbol des Krieges) und APHRODITE (Symbol des Friedens) - sowie ZEUS (Symbol des Wandels) und KRONOS (Symbol für das Ur-Zeitalter) gegenüber. Dazwischen erklingen in Form von zwei Intermezzi HERMES und URANOS. Das große Finale überlässt der Komponist POSEIDON, dem Mystiker.",
        "Der Sound von ORPHEUS III ist orchestral und kammermusikalisch zugleich. Er bewegt sich frei zwischen überirdischer Improvisation und rhythmisch-hypnotischen Grooves. Jazz-apomorphe Klänge fusionieren mit Elementen aus fremden Stilen.",
        "<i>Track listing</i>: 1)&nbsp;Ares 2)&nbsp;Aphrodite 3)&nbsp;Hermes 4)&nbsp;Zeus 5)&nbsp;Kronos 6)&nbsp;Uranos 7)&nbsp;Poseidon",
        "<i>Lineup:</i> Ruben Giannotti (tp, comp), Patrick Hamacher (ts), Benjamin Geyer (synths, rhodes), Fabian Timm (b), Philip Dornbusch (dr)"
      ],
      img: {
        shelf: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-shelf.png",
        details: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-details.png"
      },
      demos: [
        {
          id: "o3_ares",
          artist: "orpheus III",
          title: "Ares",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-ares.mp3",
              type: "audio/mp3"
            },
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-ares.ogg",
              type: "audio/ogg"
            }
          ]
        },
        {
          id: "o3_zeus",
          artist: "orpheus III",
          title: "Zeus",
          sources: [
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-zeus.mp3",
              type: "audio/mp3"
            },
            {
              api: "stoa",
              src: "https://stoa.berlin/data/catalogue/assets/audio/planeten/planeten-zeus.ogg",
              type: "audio/ogg"
            }
          ]
        }
      ]
    }
  ],
  print: [
    {
      id: "SE-802",
      slug: "vaermeland",
      title: "Värmeland",
      author: "Ruben Giannotti",
      released: "2016-05-01",
      price: "59.99",
      discount: ".2",
      details: [
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.",
        "Et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      ],
      img: {
        shelf: "https://stoa.berlin/data/catalogue/assets/print/vaermeland/vaermeland-shelf.png",
        details: "https://stoa.berlin/data/catalogue/assets/print/vaermeland/vaermeland-shelf.png"
      },
      demos: [
        {
          id: "oso_vermeland",
          artist: "Open Source Orchestra",
          title: "Värmeland",
          sources: [
            {
              api: "soundcloud",
              src: "https://api.soundcloud.com/tracks/210183646/stream",
              type: "audio/mp3"
            }
          ]
        }
      ]
    },
    {
      id: "SE-417",
      slug: "sleepin-beecroft",
      title: "Sleepin' Beecroft",
      author: "Ruben Giannotti",
      released: "2016-03-01",
      price: "59.99",
      details: [
        "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
      ],
      img: {
        shelf: "https://stoa.berlin/data/catalogue/assets/print/sleepinbeecroft/sleepinbeecroft-shelf.png",
        details: "https://stoa.berlin/data/catalogue/assets/print/sleepinbeecroft/sleepinbeecroft-shelf.png"
      },
      demos: [
        {
          id: "oso_sleepinbeecroft",
          artist: "Open Source Orchestra",
          title: "Sleepin' Beecroft (Live)",
          sources: [
            {
              api: "soundcloud",
              src: "https://api.soundcloud.com/tracks/178944110/stream",
              type: "audio/mp3"
            }
          ]
        }
      ]
    }
  ]
};
