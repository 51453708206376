import { isNumber } from 'lodash';

// Cumulative distribution function
// Emulate easing with the normal distribution
// instead of cubic bezier
const ndCDF = (x, mean, variance) => {
  return .5 * (1 + erf((x - mean) / (Math.sqrt(2 * variance))));
}

// regression fit approximation of the gaussian error function
const erf = (x) => {
  // buffer the sign of x
  const sign = (x >= 0) ? 1 : -1;
  x = Math.abs(x);
  // fixed parameters
  const a1 =  0.254829592;
  const a2 = -0.284496736;
  const a3 =  1.421413741;
  const a4 = -1.453152027;
  const a5 =  1.061405429;
  const p  =  0.3275911;
  // A&S formula 7.1.26
  const t = 1.0 / (1.0 + p*x);
  const y = 1.0 - (((((a5*t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-Math.pow(x, 2));
  return sign*y; // hence: erf(-x) === -erf(x);
}

////window.startDate = new Date();

//TODO: change constrcutor input to key value interface (js object)

export default class SmoothScroll {
  constructor(parameters) {
    if(parameters) {
      var {
        container = document.documentElement,
        fps = 30,
        durationDefault = 750,
        timingDefault = 'linear',
      } = parameters;
    }
    this.container = container;
    this.fps = fps;
    this.durationDefault = durationDefault;
    this.timingDefault = timingDefault;
  }

  //TODO: rename 'scroll()' to 'to()'?

  /**
   * @param {Object} target - node or y coordinate to which to scroll to
   * @param {Object} options
   *  `container` overrides the global container declaration if specified
   *  `duration` of the animation,
   *  `timing` specifies the scroll behaviour over time
   */

  scroll = (
    target,
    {
      container = this.container,
      duration = this.durationDefault,
      timing = this.timingDefault,
    }
  ) => {
    // console.log('start scrolling');
    // Setting up the timing props
    const timeUnit = 1000 / this.fps;
    let __timingFunction;

    switch(timing) {
      default:// linear
        __timingFunction = (x) => x;
        break;
      case 'easeIn':
        __timingFunction = (x) => Math.pow(x, 2);
        break;
      case 'easeOut':
        __timingFunction = (x) => -Math.pow(x - 1, 2) + 1;
        // alt: 1.4444*Math.log(x + 1)
        break;
      case 'easeInOut':
        __timingFunction = (x) => ndCDF(x, .5, .025);
        break;
    }

    // Use __timingFunction only on the inner of the unit interval
    // and hard code the margin values - machines are inaccurate sometimes
    const timingFunction = (x) => {
      if(x <= 0) {
        return 0;
      } else if(x > 0 && x < 1) {
        return __timingFunction(x);
      } else {
        return 1;
      }
    }

    /*
    console.log('check timing function', timingFunction(.5));

    console.log('preparing the animation',
      `iterationsAmount: ${Math.round(options.duration / timeUnit)}`,
      `scrollDistance: ${targetElement.getBoundingClientRect().top}`,
      `posInitial: ${this.scrollNode.scrollTop}`
    );

    console.log('invoking __processScroll()');
    */

    // Setting up the geometric props
    const posInitial = container.scrollTop;
    const scrollDistance = isNumber(target) ?
      target - posInitial :
      target.getBoundingClientRect().top;

    this.__processScroll({
      timeUnit,
      startTime: Date.now(),
      iterationsAmount: Math.round(duration / timeUnit),
      iterationsCount: 0,
      repeater: null,
      container,
      scrollDistance,
      posInitial,
      timingFunction
    });
  }
    
  __processScroll = ({
    timeUnit,
    startTime,
    iterationsAmount,
    iterationsCount,
    repeater,
    container,
    scrollDistance,
    posInitial,
    timingFunction
  }) => {
    repeater = requestAnimationFrame(() => {
      this.__processScroll({
        timeUnit,
        startTime,
        iterationsAmount,
        iterationsCount,
        repeater,
        container,
        scrollDistance,
        posInitial,
        timingFunction
      })
    });

    //console.log('__processScroll loop: ', iterationsCount);

    //window.currentRafRepeater = repeater;// for the cancel() method

    // Make sure that animation does only access the `scrollTop` property in the given frame rate
    const now = Date.now();
    const d = now - startTime;

    //console.log('d: ', d);

    if (d > timeUnit) {
      startTime = now - (d % timeUnit);
      iterationsCount++;

      const posFinal = scrollDistance + posInitial;
      const x = iterationsCount / iterationsAmount;
      const y = scrollDistance*timingFunction(x) + posInitial;
      let checkRepaint = y < posFinal;

      if(scrollDistance < 0) checkRepaint = y > posFinal;

      if(checkRepaint) {
        container.scrollTop = y;
      } else {
        container.scrollTop = posFinal;
        window.cancelAnimationFrame(repeater);
      }
    }
  }

  /* version of scroll() without fps bound */

  /*
  scroll = (
    targetElement,
    options = { duration: 750, timing: 'linear' }
  ) => {
    // set the main parameters
    const currentHeight = this.scrollNode.scrollTop;
    const scrollDistance = targetElement.getBoundingClientRect().top;
    // This ensures the right duration using rAFs
    const stepAverage = (scrollDistance / 7)*Math.exp(-options.duration / 400) + 5;
    let timingFunction = (x) => x;// linear timing by default;
    switch (options.timing) {
      case 'easeIn':
        timingFunction = (x) => Math.pow(x, 2);
        break;
      case 'easeOut':
        timingFunction = (x) => -Math.pow(x - 1, 2) + 1;
        // alt: 1.4444*Math.log(x + 1)
        break;
      case 'easeInOut':
        timingFunction = (x) => cdf(x, .5, .025);
        break;
      default: break;
    }
    this.__processScroll({
      posInitial: currentHeight,
      scrollDistance,
      iterationsCount: 0,
      iterationsAmount: Math.abs(scrollDistance / stepAverage),
      repeater: undefined,
      timingFunction,
    });
  }
  
  __processScroll = ({
    posInitial,
    scrollDistance,
    iterationsCount,
    iterationsAmount,
    repeater,
    timingFunction
  }) => {
    const x = iterationsCount / iterationsAmount;
    let y = scrollDistance*timingFunction(x) + posInitial;
    this.scrollNode.scrollTop = y;
    iterationsCount++;
    if(iterationsCount <= iterationsAmount) {
      repeater = requestAnimationFrame(
        () => this.__processScroll({
          posInitial,
          scrollDistance,
          iterationsCount,
          iterationsAmount,
          repeater,
          timingFunction
        })
      );
      //window.currentRafRepeater = repeater;// for the cancel() method
    } else {
      window.cancelAnimationFrame(repeater);
    }
  }

  */
  //
  //TODO: check if the cancel method is performat
  //cancel() {
  //  window.cancelAnimationFrame(window.currentRafRepeater);
  //}
  //TODO: add a destroy() method that destroys the current init.
}

export const smoothScroll = new SmoothScroll();

/* old implementation (with setTimeout) */

/*
const __animationQuantization = 16.7;//~=60Hz

const scrollSmooth = (targetElement, time) => {
  const scrollDistance = targetElement.getBoundingClientRect().top;
  const posYStart = document.getElementById('app-content').scrollTop;
  const stepsAmount = time / __animationQuantization;
  __processScroll(scrollDistance, time, posYStart, stepsAmount, 0);
}

const __processScroll = (distance, time, posYStart, stepsAmount, step) => {
  step++;
  const posY = __scrollTimingEase(step*__animationQuantization, time, distance, posYStart);
  setTimeout(() => {
    document.getElementById('app-content').scrollTop = posY;
    if(step < stepsAmount) {
      __processScroll(distance, time, posYStart, stepsAmount, step);
    }
  }, __animationQuantization);
}

const __scrollTimingEase = (t, T = 1000, d = 500, s = 0) => {
  return(
    (-8*d / (3*Math.pow(T, 3)))*Math.pow(t, 3)
    + (4*d / (Math.pow(T, 2)))*Math.pow(t, 2)
    - (d / (3*T))*t
    + s
  );
}
*/
