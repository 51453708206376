import React, { Component, Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';//BrowserRouter
//import { createBrowserHistory } from 'history';
import TypeInsurance from 'type-insurance';
import firebase from 'firebase';
import { TextContentScope } from './utilities/js/text-content-management';
import tokenStorage from './utilities/js/shop.tokenStorage';
import ScrollToTop from './utilities/js/scroll-to-top';
import polysmoothscroll from 'smoothscroll-polyfill';
import { CartContext, DetailsContext } from './context';
import cartStorage from './utilities/js/shop.cartStorage';
import Maintenance from './components/maintenance/maintenance';
import MainInterface from './components/main-interface/main-interface';
import Footer from './components/footer/footer';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './App.css';

const isMaintenance = false;
const routesDir = './routes/';
const loadingTimeMinDefault = 800;

const genLazyRoute = async (routeModule, timeMin = loadingTimeMinDefault) => {
  return Promise.all([
    routeModule,
    new Promise(resolve => setTimeout(resolve, timeMin))
  ]).then(([moduleExports]) => moduleExports);
}

const __dir = routesDir.replace(/([/]?)$/, '');

const Home = lazy(() => genLazyRoute(import(`${__dir}/Home`), 1900));
const Articles = lazy(() => genLazyRoute(import(`${__dir}/Articles`)));
const Imprint = lazy(() => genLazyRoute(import(`${__dir}/Imprint`)));
const Legal = lazy(() => genLazyRoute(import(`${__dir}/Legal`)));
const Data = lazy(() => genLazyRoute(import(`${__dir}/Data`)));
const Payment = lazy(() => genLazyRoute(import(`${__dir}/Payment`)));
const Address = lazy(() => genLazyRoute(import(`${__dir}/Address`)));
const Checkout = lazy(() => genLazyRoute(import(`${__dir}/Checkout`)));
const OrderCompleted = lazy(() => genLazyRoute(import(`${__dir}/OrderCompleted`)));
const OrderError = lazy(() => genLazyRoute(import(`${__dir}/OrderError`)));
const Klarna = lazy(() => genLazyRoute(import(`${__dir}/Klarna`)));
const Amazon = lazy(() => genLazyRoute(import(`${__dir}/Amazon`)));
const Admin = lazy(() => genLazyRoute(import(`${__dir}/Admin`)));

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT,
};

firebase.initializeApp(firebaseConfig);
let firebaseDatabase = firebase.database();

//const history = createBrowserHistory();

const trunc = (str, length = 100, ending = '') => {
  if (str.length > length) {
    return str.slice(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const generateTimedCartHash = (cart) => {
  const d = new Date();
  const { platform, productSub } = navigator;

  const CART_REDUCTION_STRING = cart.reduce(
    (red, curr) => red + curr.id + curr.qty, ''
  );

  const CURR_DATE_UTC = Math.floor(d.getTime() / 1000);

  const identifier = trunc(`${
    platform + productSub + CART_REDUCTION_STRING
  }`, 255);//.split('').sort(() => 0.5 - Math.random()).join('');

  const HASH = Math.abs(identifier.split('').reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0));

  return (((CURR_DATE_UTC + HASH) * (CURR_DATE_UTC + HASH + 1)) / 2) + HASH;
}

const Loading = () => {
  return(
    <div className="loading">
      <div className="loading__icon">
        {[...Array(3).keys()].map(key => <div key={key}></div>)}
      </div>
    </div>
  );
};

class App extends Component {
  constructor(){
    super();
    this.state = {
      cartContextData: {
        cart: [],
        total: 0,
        netValue: 0,
      },
      detailsContextData: {
        expanded: false,
      },
      shoppingModalActive: false,
    }
    this.appContentNode = null;
  }

  syncCartContext = () => {
    const cart = cartStorage.get();
    const values = cartStorage.getValues();
    this.setState({ cartContextData: { cart, ...values } });
  };

  setDetailsContext = detailsContextData => {
    this.setState({ detailsContextData });
  }

  handleOrderMounted = () => {
    if(this.state.shoppingModalActive) {
      this.handleShoppingModalToggle();
    }
  }

  handleShoppingModalClose = () => {
    this.setState({ shoppingModalActive: false });
  }

  handleShoppingModalToggle = () => {
    let { shoppingModalActive, cartContextData } = this.state;

    if(!shoppingModalActive) {
      const _r = Math.round(1000 * Math.random());
      const { string } = new TypeInsurance(_r);
      const orderId = generateTimedCartHash(cartContextData.cart);
      tokenStorage.set(orderId + string);
    } else {
      tokenStorage.unset();
    }

    shoppingModalActive = !shoppingModalActive
    this.setState({ shoppingModalActive });
  }

  componentDidMount(){
    document.title = 'stoa.berlin';
    sessionStorage.setItem('stoa_isLogged', 'false');
    polysmoothscroll.polyfill();
    this.syncCartContext();
    this.appContentNode = document.getElementById('app-content');
  }

  render() {
    //console.log(history.location);
    const {
      state: {
        cartContextData,
        detailsContextData,
        shoppingModalActive,
      },
      appContentNode,
      syncCartContext,
      setDetailsContext,
      handleShoppingModalToggle,
      handleShoppingModalClose,
      handleOrderMounted,
    } = this;

    const cartContext = {
      cartContextData,
      syncCartContext,
    };

    const detailsContext = {
      detailsContextData,
      setDetailsContext,
    };

    const mainInterfaceProps = {
      shoppingModalActive,
      handleShoppingModalToggle,
      handleShoppingModalClose,
      setDetailsContext,
      syncCartContext,
    }

    const PageNotFound = () => <div>not found</div>;

    const checkoutRouteProps = { firebaseDatabase, syncCartContext };
    
    return (
      <Router>{/*history={history}*/}
        <Suspense fallback={<Loading />}>
          <TextContentScope>
            <CartContext.Provider value={cartContext}>
              <DetailsContext.Provider value={detailsContext}>
                {isMaintenance && <Maintenance />}
                <MainInterface {...mainInterfaceProps} />
                {/*<main id="app-content" className="wrapper">*/}
                  <section role="main" id="app-content" className="wrapper">
                    <SimpleBar style={{ height: '100vh', overflowX: 'hidden' }}>
                    <div id="page-top"></div>
                    <ScrollToTop containerNode={appContentNode}>
                      {/*scrollHandler={smoothScrollToTop}*/}
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/articles/:slug" component={Articles} />
                        <Route path="/imprint" component={Imprint} />
                        <Route path="/legal" component={Legal} />
                        <Route path="/data-privacy" component={Data} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/address" component={Address} />
                        <Route path="/checkout" render={() => <Checkout {...checkoutRouteProps} />} />
                        <Route path="/order-completed" render={() => <OrderCompleted {...checkoutRouteProps} />} />
                        <Route path="/order-error" component={OrderError} />
                        <Route path="/klarna" render={() => <Klarna {...checkoutRouteProps} />} />
                        <Route
                          path="/amazon"
                          render={() => <Amazon onOrderMounted={handleOrderMounted} />}
                        />
                        <Route path="/admin" component={Admin} />
                        <Route component={PageNotFound} />
                      </Switch>
                      <Footer />
                    </ScrollToTop>
                  </SimpleBar>
                </section>
                {/*</main>*/}
              </DetailsContext.Provider>
            </CartContext.Provider>
          </TextContentScope>
        </Suspense>
      </Router>
    );
  }
}

export default App;
