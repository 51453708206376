import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cartStorage from '../../../utilities/js/shop.cartStorage';
import validate from '../../../utilities/js/shop.validate';
import { TextContentConsumer } from '../../../utilities/js/text-content-management';
import { formatCash } from '../../../utilities/js/shop.config';
import Icons from '../../../utilities/js/icons';
import './cart-content.css';

export default class CartContent extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      qty: PropTypes.number,
    })),
    syncHandler: PropTypes.func,
    netValue: PropTypes.number,
  }

  static defaultProps = {
    articles: [],
  }

  handleDelete = (id) => {
    cartStorage.delete(id);
    this.props.syncHandler();
  }
  
  handleReduce = (id) => {
    if(cartStorage.testQty(id, (qty) => qty > 1)) {
      cartStorage.add(id, -1);
      this.props.syncHandler();
    }
  }
  
  handleIncrement = (id) => {
    cartStorage.add(id, 1);
    this.props.syncHandler();
  }

  handleQty = ({ target: { value } }, id) => {
    cartStorage.qty(id, Number(value));
    this.props.syncHandler();
  }

  componentDidMount() {
    this.props.syncHandler();
  }
  
  render() {
    const { articles, netValue } = this.props;
    return(
      validate.isEmptyCart() ? (
        <TextContentConsumer>
          {({ notifications }) => (
            <div className="cart-empty-notification">
              {notifications.cartEmpty}
            </div>
          )}
        </TextContentConsumer>
      ) : (
        <div className="cart-content">
          <div className="cart-content__body">
            {articles.map(
              ({ id, name, price, qty }) => (
                <CartContentRow
                  key={id}
                  id={id}
                  name={name}
                  price={price}
                  quantity={qty}
                  quantityHandler={this.handleQty}
                  reduceHandler={this.handleReduce}
                  incrementHandler={this.handleIncrement}
                  deleteHandler={this.handleDelete}
                />
              )
            )}
          </div>
          <div className="cart-content__total">
            {formatCash(netValue)}
          </div>
        </div>
      )
    );
  }
}

const CartContentRow = ({
  id,
  name,
  price,
  quantity,
  deleteHandler,
  quantityHandler,
  reduceHandler,
  incrementHandler,
}) => (
  <div key={id} className='item'>
    <div className='cell header'>{name}</div>
    <div className='cell body price'>
      {formatCash(price)}
    </div>
    <div className='cell side'>
      <CartDelete callback={deleteHandler} id={id} />
    </div>
    <div className='cell body'>
      <CartReduce callback={reduceHandler} id={id} />
      <input
        type='number'
        value={quantity}
        onChange={(e) => quantityHandler(e, id)} />
      <CartIncrement
        callback={incrementHandler}
        id={id}
      />
    </div>
    <div className='cell side'>
      {formatCash(price*quantity)}
    </div>
  </div>
);

CartContentRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  quantityHandler: PropTypes.func.isRequired,
  reduceHandler: PropTypes.func.isRequired,
  incrementHandler: PropTypes.func.isRequired,
}

const CartReduce = ({ id, callback }) => (
  <CartControls cartCallback={callback} id={id}>
    <Icons.Controls.Reduce />
  </CartControls>
);

CartReduce.propTypes = {
  id: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}

const CartIncrement = ({ id, callback }) => (
  <CartControls cartCallback={callback} id={id}>
    <Icons.Controls.Increment />
  </CartControls>
);

CartIncrement.propTypes = {
  id: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}

const CartDelete = ({ id, callback }) => (
  <CartControls cartCallback={callback} id={id}>
    <Icons.Controls.Delete />
  </CartControls>
);

CartDelete.propTypes = {
  id: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}

const CartControls = ({ id, cartCallback, children }) => (
  <button
    className="cart-controls"
    onClick={() => { cartCallback(id) }}
  >
    {children}
  </button>
);

CartControls.propTypes = {
  id: PropTypes.string.isRequired,
  cartCallback: PropTypes.func.isRequired,
  children: PropTypes.node,
}
