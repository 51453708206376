import { indicators } from './shop.config';
import validate from './shop.validate';

class CartStorage {
  constructor() {
    this.namespace = 'STOA_cart_storage';
  }

  /**
   * @returns {String} String of shopping cart in localStorage
   */
  __get() {
    return window.localStorage[this.namespace];
  }

  /**
   * @returns {Array} JSON array of shopping cart in localStorage
   */
  get() {
    const cartStorage = this.__get();

    if(cartStorage) {
      try {
        JSON.parse(cartStorage);
      } catch(error) {
        console.log('The local cart storage is not parsable', error);
        return false;
      }
      return JSON.parse(cartStorage);
    } else {
      throw new Error('The local cart storage is undefined');
    }
  }

  /**
   * @returns {Object} JSON object of all values provided by the class
   */
  getValues() {
    return {
      total: this.total(),
      netValue: this.netValue(),
      grossSum: this.grossSum(),
      shippingEval: this.shippingEval(),
      valueFinal: this.valueFinal(),
      vatAmount: this.vatAmount()
    }
  }

  /**
   * @param {Array} content - JSON array of shopping cart
   */
  set(content) {
    if(Array.isArray(content)) {
      window.localStorage.setItem(this.namespace, JSON.stringify(content));
    } else {
      throw new Error(
        'The content has to be an array'
      );
    }
  }

  __setEmpty() {
    this.set([]);
  }
  
  /**
   * Initializes the localStorage with an empty array
   */
  __init() {
    if(!this.__get()) {
      this.__setEmpty();
    }
  }

  /**
   * Clears the cart in localStorage
   */
  clear() {
    this.__setEmpty();
  }

  /**
   * Returns the rounded value of `num` padded to 2 fixed decimal digits
   * @param {number} num
   * @returns {number}
   */
  __commercialRound = (num) => {
    return Number(Number(num).toFixed(2));   
  }

  /**
   * Checks if item with ID `id` is in the localStorage and if so at what position of the array
   * @returns {number} Position (array index) of item with ID `id` in localStorage
   */
  check(id) {
    //const cart = this.get();
    /*
    let indexReturn = null;
    let flagReturn = cart.map((item, index) => {
      if(item['id'] === id) { indexReturn = index; return true; }
      return false;
    }).reduce((p, c) => p || c, false);
    return { 'flag': flagReturn, 'index': indexReturn };
    */
    // another approach: if -1: not found, else actual array position
    /*  
    for(let i = 0; i < cart.length; i++)
      if(cart[i].id === id) return i;
    return -1;
    */
    return this.get().findIndex((item) => item.id === id);
  }

  /**
   * Fetches the item with the ID `id` from localStorage
   * @param {string} id
   * @returns {Object} (JSON object) containing the item with ID `id` in localStorage
   */
  fetch(id) {
    const processFetch = this.get().filter(
      cartItem => cartItem.id === id
    );
    if(processFetch.length > 1) {
      throw new Error(`There were multiple items with the ID ${id}`);
    } else if(processFetch.length === 1) {
      return processFetch[0];
    } else {
      return {};
    }
  }

  /**
   * Adds an article to localStorage with the givens parameters via the set method
   * @param {string} id - Article ID
   * @param {number} qty - Quantity that should be added
   * @param {string} price - Price of the article
   * @param {string} name - Article name
   */
  add(id, qty, price, name, url) {
    if(validate.isString(id) && validate.isNumber(qty)) {
      let cart = this.get();
      let cartItemPosition = this.check(id);

      if(cartItemPosition !== -1) {
        cart[cartItemPosition]['qty'] += qty;
      } else if(price && name) {
        cart.push({
          id,
          name,
          price,
          qty,
          url,
          vat: indicators.vatRate
        });
      } else {
        console.log(
          `The item was passed without specifying name and price
           or was not found at all in localStorage`
        );
      }

      this.set(cart);
    } else {
      throw new Error(
        'The ID has to be passed as a string and the quantity as a number'
      );
    }
  }

  /**
   * Deletes the article with the ID `id` from localStorage
   * @param {string} id
   */
  delete(id) {
    const cart = this.get();
    this.set(cart.filter(item => item.id !== id));
  }

  /**
   * Sets the quantity of article with the ID `id` to the value `val`
   * @param {string} id
   * @param {number} val
   */
  qty(id, val) {// TODO: handle else case
    let cart = this.get();  
    if(val > 0) {
      cart[this.check(id)].qty = val;
      this.set(cart);
    }//else {}
  }

  /**
   * Performs the `callback` on the quantity of the item with the ID `id` in localStorage
   * @param {string} id
   * @param callback
   */
  testQty(id, callback) {
    const selected = this.fetch(id);
    return callback(selected.qty);
  }

  /**
   * Returns the number of items in localStorage
   * @returns {number}
   */
  count() {
    return this.get().length;
  }

  /**
   * Returns the total quantity of all items in localStorage
   * @returns {number}
   */
  total() {
    return this.get().map(({ qty }) => qty).reduce((p, c) => p + c, 0);
  }

  /**
   * Returns the net value of all items in localStorage
   * @returns {number}
   */
  netValue() {
    return this.get().map(
      ({ qty, price }) => qty * price
    ).reduce((p, c) => p + c, 0);
  }

  /**
   * Returns the gross sum of all items in localStorage
   * @returns {number}
   */
  grossSum() {
    return this.__commercialRound(
      this.netValue() * (1 + indicators.vatRate)
    );
  }

  /**
   * Returns the evaluated shipping cost
   * @returns {number}
   */
  shippingEval() {
    return cartStorage.total() < indicators.shippingThreshhold ? indicators.shippingCost : 0;
  }

  /**
   * Returns the very final amount to charge
   * @returns {number}
   */
  valueFinal(){
    return Number(
      indicators.vatIncluded ? this.netValue() : this.grossSum()
    ) + this.shippingEval();
  }

  /**
   * Returns the VAT amount of all items in localStorage
   * @returns {number}
   */
  vatAmount() {
    return this.__commercialRound(
      this.netValue() * indicators.vatRate
    );
  }

  //for fallback only until integration is complete
  //regularly check if they are still present in the codebase and delete if not
  load() { return true; }
  update() { return true; }
}

const cartStorage = new CartStorage();
cartStorage.__init();

export default cartStorage;
