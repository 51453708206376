import React, { Component } from 'react';
import { flattenDeep, isEqual, difference } from 'lodash';
import validate from './shop.validate';

/**
 * Returns all the keys of `obj` sorted hierarchically
 * @param {object} obj
 * @returns {array}
 */

const allObjectKeysHirarchy = (obj) => {
  return Object.keys(obj).map((key) =>
    Object.keys(obj[key]).length !== 0
    && (typeof obj[key] === 'object' && obj[key] !== null) ?
      [key, allObjectKeysHirarchy(obj[key])] :
      key
  );
}

/**
 * Returns a non-hierarchic sorted collection of all keys of `obj`
 * @param {object} obj
 * @returns {array}
 */

export const allObjectKeys = (obj) => {
  return flattenDeep(
    allObjectKeysHirarchy(obj)
  ).concat().sort();
}

/**
 * Checks if `obj1` has the overall same keys as `obj2`
 * @param {object} obj
 * @param {object} comp
 * @returns {boolean}
 */

const isAllObjectKeysEqual = (obj1, obj2) => {
  return isEqual(
    allObjectKeys(obj1),
    allObjectKeys(obj2),
  );
}

class TextContentManagement {
  constructor() {
    this.path = '';
    this.mod = {};
  }

  config = ({ language, path, mod }) => {
    this.path = path;
    this.mod = mod;
    localStorage.setItem('TCMS_defaultLanguage', language);
    localStorage.setItem('TCMS_language', language);
  }

  /**
   * Sets the language local storage to `language`
   * @param {string} language
   */

  setLanguage = (language) => {
    if(validate.isString(language)) {
      localStorage.setItem('TCMS_language', language);
    } else {
      throw new Error(`
        The language specifier has to be a string
      `);
    }
  }

  /**
   * Gets the language local storage
   * @returns {string} holding the current language
   */

  getLanguage = () => {
    const language = localStorage.getItem('TCMS_language');
    if(language) {
      return language;
    } else {
      throw new Error(`
        No language was set
      `);
    }
  }

  /**
   * Fetches the text content data asynchronously.
   * By default if the class config recieves a module through the `mod` key,
   * this overrides the resource loaded by `path`.
   * The languages provided at the top level of the data
   * have to provide the same keys.
   * The fetching will fail if they don't.
   * If no `language` is specified this function assumes
   * that the top level of the text content data does not distinguish different languages.
   * @returns {Promise<object>} Promise resolving to the object holding the text content data
   */

  fetchMessages = async () => {
    let defaultLanguage, language;
    try {
      defaultLanguage = localStorage.getItem('TCMS_defaultLanguage');
      language = this.getLanguage();
    } finally {
      if(defaultLanguage === 'undefined') defaultLanguage = undefined;
      if(language === 'undefined') language = undefined;
    }
    const { path, mod } = this;
    let data = await Promise.resolve(mod);
    if(!mod) {
      data = await import(`./${path.replace(/^(\.?[/]?)/, '')}`);
    }
    if(data.hasOwnProperty('default')) data = data.default;
    if(!language) {
      return data;
    } else {
      if(data.hasOwnProperty(language)) {
        let languageConforms = true;
        if(defaultLanguage !== language) {
          languageConforms = isAllObjectKeysEqual(data[defaultLanguage], data[language]);
        }
        if(languageConforms) {
          return data[language];
        } else {
          const diff1 = difference(
            allObjectKeys(data[defaultLanguage]),
            allObjectKeys(data[language])
          );
          const diff2 = difference(
            allObjectKeys(data[language]),
            allObjectKeys(data[defaultLanguage])
          );
          throw new Error(`
            The language you are trying to load does not contain the same
            keys as the default language.
            The following keys seem to be missing: [${diff1.concat(diff2)}]
          `);
        }
      } else {
        throw new Error(`
          The language "${language}" is not provided.
        `);
      }
    }
  }
}

const TCMS = new TextContentManagement();
//messages.__init();

export default TCMS;

TCMS.config({
  language: 'de',
  path: './messages.js',
});

//TODO: document React facilities

const TextContentContext = React.createContext({});

export class TextContentScope extends Component {
  constructor(){
    super();
    this.state = {
      data: {},
    }
  }

  componentDidMount() {
    (async () => {
      try {
        const data = await TCMS.fetchMessages();
        //console.table(data);
        this.setState({ data });
      } catch(error) {
        console.error('Can not load text content!', error);
      }
    })();
  }
  
  render() {
    const {
      props: { children },
      state: { data },
    } = this;
    return(
      <TextContentContext.Provider value={data}>
        {children}
      </TextContentContext.Provider>
    );
  }
}

export const TextContentConsumer = ({ children }) => (
  <TextContentContext.Consumer>
    {children}
  </TextContentContext.Consumer>
);
