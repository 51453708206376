import cartStorage from './shop.cartStorage';
import fetchCatalogue from './shop.catalogue';

class Validate {
  constructor() {
    this.numbersrx = "^[0-9]+$";
    this.lettersrx = "^[a-zA-Z]+$";
    this.textrx = "^[a-zA-ZäöüÄÖÜß0-9 .!?,;-]+$";
    this.emailrx = "^[a-z0-9.!#$%&'*+=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$";
    this.catalogue = [];
    this.__init();
  }//"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
//^(?!\.)^(?!.*([."])\1)[a-zA-Z0-9_.+"-]+(?<!\.)@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}  

  __init = async () => {
    const response = await fetchCatalogue();
    this.catalogue = response;
  }

  isNumber = (i) => {
    return (i === 0 || !(!i || !(Number(i) === i)));
  }
  
  isString = (i) => {
    return (typeof i === 'string' || i instanceof String);
  }

  isObject = (i) => {
    return (typeof i === 'object' && i !== null);
  }

  isEmptyString = (i) => {
    if(!this.isString(i)) {// /^(undefined|boolean|object|number|function)/.exec(typeof i)
      return false;
    } else {
      return (!i.replace(/\s/g, '').length);
    }
  }

  isEmptyCart = () => {
    const cart = cartStorage.get();
    return (!cart || cart.length === 0);
  }

  numbers = (i) => {
    let rx = new RegExp(this.numbersrx);
    if (this.isNumber(i)) {
      return true;
    } else if (this.isString(i)) {
      return rx.test(i);
    } else {
      return false;
    }
  }

  letters = (i) => {
    let rx = new RegExp(this.lettersrx, 'i');
    if (this.isString(i)) {
      return rx.test(i);
    } else {
      return false;
    }
  }

  text = (i) => {
    let rx = new RegExp(this.textrx);
    if (this.isString(i)) {
      return rx.test(i);
    } else {
      return false;
    }
  }

  email = (i) => {
    let rx = new RegExp(this.emailrx);
    if (this.isString(i)) {
      return rx.test(i);
    } else {
      return false;
    }
  }

  textInput = (i) => {
    return (!this.isEmptyString(i) && this.text(i));
  }

  emailInput = (i) => {
    return (!this.isEmptyString(i) && this.email(i));
  }

  selectInput = (i) => {
    return (!this.isEmptyString(i));
  }

  cartSanity = () => {
    const { catalogue } = this;
    const cart = cartStorage.get();
    if(cart.length === 0) {
      return true;
    } else {
      const catalogueJoined = [...catalogue.audio, ...catalogue.print];
      let returnValue = true;
      cart.forEach((item) => {
        const catalogueFiltered = catalogueJoined.filter(x => x.id === item.id);
        if(catalogueFiltered.length !== 1) {
          throw new Error(
            'The catalogue seems to be not properly loadable or corrupted.'
          );
        } else {
          const { title, author, price } = catalogueFiltered[0];
          if(
            !(
              Number(price) === item.price
              && item.name.includes(title)
              && item.name.includes(author)
            )
          ) {
            returnValue = false;
          }
        }
      });
      return returnValue;
    }
  }
}

const validate = new Validate();
//validate.__init();

export default validate;
