import React from 'react';

export const CartContext = React.createContext({
  cart: [],
  total: 0,
  netValue: 0,
});

export const DetailsContext = React.createContext({
  expanded: false,
});
