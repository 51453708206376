import React from 'react';
import { Link } from 'react-router-dom';
import { TextContentConsumer } from '../../utilities/js/text-content-management';
import './footer.css';
import logo from '../../logo.svg';

//TODO: change route names to english (accordingly in router config in App.js)
const Footer = () => {
  return(
    <TextContentConsumer>
      {() => (// use {({ navigation }): navigation.home, navigation.imprint, ...
        <section id="footer" className="container static footer-section">
          <div className="logo-wrapper">
            <img src={logo} alt="STOA" />
          </div>
          <div className="links-wrapper">
            <Link to="/">Home</Link>
            <Link to="/imprint">Impressum</Link>
            <Link to="/legal">AGB</Link>
            <Link to="/data-privacy">Datenschutz</Link>
            <Link to="/payment">Sicheres Bezahlen</Link>
          </div>
          <div className="copyright-wrapper">
            &copy; 2019-2021 // Made with a
            <span role="img" aria-label="computer">&#x1F4BB;</span>
            by STOA //
          </div>
        </section>
      )}
    </TextContentConsumer>
  );
}

export default Footer;
