import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import validate from '../../../utilities/js/shop.validate';
import { escapeHtml } from '../../../utilities/js/shop.sanitize';
import {
  Form, Input, Submit
} from '../form/form';
import Icons from '../../../utilities/js/icons';
import './login.css';

export default class Login extends Component {
  static propTypes = {
    loginCallback: PropTypes.func,
  }

  constructor(props){
    super(props);
    this.inputValuesInitial = {
      login: '',
      password: '',
    }
    this.state = {
      ...this.inputValuesInitial,
      expanded: false,
      submitStatus: 'idle',
    }
  }
  
  validateInput = (login, password) => {
    return Boolean(
      validate.textInput(login) &&
      validate.textInput(password)
    );
  }

  handleExpandToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  }
  
  handleChange = (key, val) => {
    this.setState({ [key]: escapeHtml(val) });
  }
  
  handleSubmit = async () => {
    this.setState({ submitStatus: 'checking' });
    const { login, password } = this.state;
    const isValidSubmission = this.validateInput(
      login, password
    );
    
    if(isValidSubmission) {
      this.setState({ submitStatus: 'sending' });
      const response = await axios.get(
        `/backend/php/login/?login=${login}&password=${password}`
      );
      if(response.data) {
        this.setState({ submitStatus: 'success' });
        this.props.loginCallback(true);
        window.sessionStorage.setItem('stoa_logged_in', 'true');
      } else {
        this.setState({ submitStatus: 'failure' });
      }
    }
  }

  render() {
    const { expanded, submitStatus } = this.state;
    const cls = `login-wrapper ${expanded ? 'expanded' : ''}`;
    return(
      <div className={cls}>
        <div className="login-expander" onClick={this.handleExpandToggle}>
          <Icons.Controls.Next />
        </div>
        <h3>Login</h3>
        <Form>
          <Input
            type="text"
            name="login"
            label="Login"
            required={true}
            parentChange={this.handleChange}
            submitStatus={submitStatus}
          />
          <Input
            type="password"
            name="password"
            label="Password"
            required={true}
            parentChange={this.handleChange}
            submitStatus={submitStatus}
          />
          <Submit
            text="Login"
            onClick={this.handleSubmit}
            submitStatus={submitStatus}
          />
        </Form>
      </div>
    );
  }
}
