import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { CartContext } from '../../context';
import { Link, useHistory } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import cartStorage from '../../utilities/js/shop.cartStorage';
import Icons from '../../utilities/js/icons';
import ShoppingModal from './shopping-modal/shopping-modal';
import navContent from './navContent';
import logo from '../../logo.svg';
import './main-interface.css';

// TODO: remove deprecatred details context construction
export default function MainInterface({
  shoppingModalActive,
  handleShoppingModalToggle,
  handleShoppingModalClose,
  setDetailsContext,
  syncCartContext,
}) {
  const [state, setState] = useState({
    pageTop: true,
    navActive: false,
    //modalActive: false,
    //navToggleVisible: true,
  });

  const history = useHistory();

  const handleNavToggle = () => {
    setState({ ...state, navActive: !state.navActive });
  }

  const handleNavClick = () => {
    setState({ ...state, navActive: false });
    //setDetailsContext({ expanded: false });
  }

  const historyIncludes = array => {
    return array.reduce(
      (acc, val) => acc || history.location.pathname.includes(val),
      false
    );
  }

  /*
  const handleModalClose = () => {
    setState({ ...state, modalActive: false });
  }

  const handleNavToggleVisibility = () => {
    setState({ ...state, navToggleVisible: !state.navToggleVisible });
  }
  */

  useEffect(() => {
    const root = document.querySelector('#app-content');
    const rootMargin = '150px';
    const IntersectionObserverOptions = { root, rootMargin };

    const observer = new IntersectionObserver(([entry]) => {
      setState({ ...state, pageTop: entry.isIntersecting })
    }, IntersectionObserverOptions);

    observer.observe(document.querySelectorAll('#app-content')[0].children[0]);
  }, []);
  
  const { pageTop, navActive } = state;
  const cls = `main-interface ${pageTop ? '' : 'scrolled'}`;

  return(
    <div className={cls}>
      <Logo pageTop={pageTop}>
        <a href='/'><img src={logo} alt='stoa-logo' /></a>
      </Logo>
      <Controls>
        <NavToggle
          isActive={navActive}
          handleClick={handleNavToggle}
        />
        {!historyIncludes(['/address', '/checkout', '/klarna']) &&
          <ModalToggle
            isActive={shoppingModalActive}
            toggleHandler={handleShoppingModalToggle}
          />
        }
      </Controls>
      <Nav
        items={navContent.items}
        icons={navContent.iconset}
        isActive={navActive}
        closeHandler={handleNavClick}
      />
      <ShoppingModal
        active={shoppingModalActive}
        toggleHandler={handleShoppingModalToggle}
        closeHandler={handleShoppingModalClose}
        setDetailsContext={setDetailsContext}
        syncCartContext={syncCartContext}
      />
    </div>
  ); 
}

MainInterface.propTypes = {
  shoppingModalActive: PropTypes.bool,
  handleShoppingModalToggle: PropTypes.func,
  handleShoppingModalClose: PropTypes.func,
  setDetailsContext: PropTypes.func,
  syncCartContext: PropTypes.func,
}

/*
export default class MainInterface extends Component {
  static propTypes = {
    shoppingModalActive: PropTypes.bool,
    handleShoppingModalToggle: PropTypes.func,
    handleShoppingModalClose: PropTypes.func,
    setDetailsContext: PropTypes.func,
    syncCartContext: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      pageTop: true,
      navActive: false,
      modalActive: false,
      navToggleVisible: true,
    }
  }

  handleNavToggle = () => {
    this.setState({
      navActive: !this.state.navActive,
    });
  }

  handleNavClick = () => {
    this.setState({
      navActive: false,
    });
    this.props.setDetailsContext({ expanded: false });
  }

  handleModalClose = () => {
    this.setState({
      modalActive: false,
    });
  }

  handleNavToggleVisibility = () => {
    this.setState({
      navToggleVisible: !this.state.navToggleVisible,
    });
  }

  componentDidMount() {
    const root = document.querySelector('#app-content');
    const rootMargin = '150px';
    const IntersectionObserverOptions = { root, rootMargin };
    const observer = new IntersectionObserver(([entry]) => {
      this.setState({ pageTop: entry.isIntersecting })
    }, IntersectionObserverOptions);
    observer.observe(document.querySelectorAll('#app-content')[0].children[0]);
  }

  render() {
    const {
      props: {
        shoppingModalActive,
        handleShoppingModalToggle,
        handleShoppingModalClose,
        setDetailsContext,
        syncCartContext,
      },
      state: {
        pageTop,
        navActive,
      },
    } = this;

    const cls = `main-interface ${pageTop ? '' : 'scrolled'}`;

    return(
      <div className={cls}>
        <Logo pageTop={pageTop}>
          <a href='/'><img src={logo} alt='stoa-logo' /></a>
        </Logo>
        <Controls>
          <NavToggle
            isActive={navActive}
            handleClick={this.handleNavToggle}
          />
          {(!window.location.href.includes('/address') && !window.location.href.includes('/checkout')) &&
            <ModalToggle
              isActive={shoppingModalActive}
              toggleHandler={handleShoppingModalToggle}
            />
          }
        </Controls>
        <Nav
          items={navContent.items}
          icons={navContent.iconset}
          isActive={navActive}
          closeHandler={this.handleNavClick}
        />
        <ShoppingModal
          active={shoppingModalActive}
          toggleHandler={handleShoppingModalToggle}
          closeHandler={handleShoppingModalClose}
          setDetailsContext={setDetailsContext}
          syncCartContext={syncCartContext}
        />
      </div>
    ); 
  }
}

*/

const Logo = ({ pageTop, children }) => (
  <div className={`logo ${pageTop ? '' : 'scrolled'}`}>
    {children}
  </div>
);

Logo.propTypes = {
  pageTop: PropTypes.bool,
  children: PropTypes.node,
}

Logo.defaultProps = {
  pageTop: true,
  children: '',
}

const Controls = ({ children }) => (
  <div className="controls">{children}</div>
);

Controls.propTypes = {
  children: PropTypes.node,
}

Controls.defaultProps = {
  children: '',
}

const NavToggle = ({ isActive, handleClick }) => (
  <button
    type="button"
    className={`hamburger hamburger--emphatic ${isActive ? 'is-active' : ''}`}
    onClick={handleClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
);

NavToggle.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
}

NavToggle.defaultProps = {
  isActive: false,
}

class ModalToggle extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    toggleHandler: PropTypes.func,
  }

  static defaultProps = {
    isActive: false,
  }

  constructor(props){
    super(props);
    this.state = {
      totalQty: 0,
    }
  }

  // syncState = () => {
  //   const totalQty = cartStorage.total();
  //   if(totalQty != null) {
  //     this.setState({ totalQty });
  //   }
  // }

  componentDidMount() {
    const totalQty = cartStorage.total();
    if(totalQty != null) {
      this.setState({ totalQty });
    }
  }

  render() {
    const { isActive, toggleHandler } = this.props;
    const cls = `modal-toggle ${isActive ? 'active' : ''}`;
    const icon = isActive ? <Icons.Shop.Cart /> : <Icons.Shop.Bag />;
    return(
      <CartContext.Consumer>
        {({ cartContextData }) => (
          <button
            className={cls}
            onClick={toggleHandler}
            data-totalqty={cartContextData.total}
          >
            {icon}
          </button>
        )}
      </CartContext.Consumer>
    );
  }
}

const Nav = ({ isActive, closeHandler, items, icons }) => (
  <div className={`nav ${isActive ? 'nav--active': ''}`}>
    <div className="nav-items-container">
      <div className="nav-items">
        {items.map(({ caption, iconcoordinates, destination }) => (
          <NavItem
            key={caption}
            caption={caption}
            destination={destination}
            iconcoordinates={iconcoordinates}
            icons={icons}
            closeHandler={closeHandler}
          />
        ))}
      </div>
      <NavCollapse handleClick={closeHandler} />
    </div>
  </div>
);

Nav.propTypes = {
  isActive: PropTypes.bool,
  closeHandler: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    caption: PropTypes.string,
    iconcoordinates: PropTypes.string,
    destination: PropTypes.object,
  })),
  icons: PropTypes.string,
}

Nav.defaultProps = {
  isActive: false,
  items: [],
}

const NavItem = ({
  destination, closeHandler, caption, icons, iconcoordinates,
}) => {
  const { to, type } = destination;
  const imgSrc = `${icons}#svgView(viewBox(${iconcoordinates}))`;
  const navItemContent =
    <div>
      <span>
        <img className="nav-icon" src={imgSrc} alt={`navigate to ${to}`}/>
      </span>
      <span>{caption}</span>
    </div>;

  const navItemProps = { to, onClick: closeHandler };

  if(type === 'hash') {
    return <NavHashLink {...navItemProps}>{navItemContent}</NavHashLink>;
  } else if(type === 'route') {
    return <Link {...navItemProps}>{navItemContent}</Link>;
  }
}

NavItem.propTypes = {
  destination: PropTypes.shape({
    to: PropTypes.string,
    type: PropTypes.string,
  }),
  closeHandler: PropTypes.func,
  caption: PropTypes.string,
  icons: PropTypes.string.isRequired,
  iconcoordinates: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  caption: '',
}

const NavCollapse = ({ handleClick }) => (
  <button className="nav-collapse" onClick={handleClick}>
    <Icons.Navigation.ArrowTop />
  </button>
);

NavCollapse.propTypes = {
  handleClick: PropTypes.func,
}
