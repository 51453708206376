import iconset from './nav-iconset.svg';

const items = [
  {
    caption: "Home",
    iconcoordinates: "0,0,50,50",
    destination: { type: "hash", to: "/#home" }
  },
  {
    caption: "Shop",
    iconcoordinates: "50,0,50,50",
    destination: { type: "hash", to: "/#shop" }
  },
  {
    caption: "About",
    iconcoordinates: "100,0,50,50",
    destination: { type: "hash", to: "/#about" }
  },
  {
    caption: "Kontakt",
    iconcoordinates: "150,0,50,50",
    destination: { type: "hash", to: "/#contact" }
  },
  {
    caption: "Impressum",
    iconcoordinates: "200,0,50,50",
    destination: { type: "route", to: "/imprint" }
  }
];

export default { items, iconset };
