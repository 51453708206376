import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validate from '../../../utilities/js/shop.validate';
import { escapeHtml } from '../../../utilities/js/shop.sanitize';
import './form.css';

export const Form = ({ children }) => (
  <div className="form-wrapper">{children}</div>
);

Form.propTypes = {
  children: PropTypes.node,
}

Form.defaultProps = {
  children: '',
}

export class Input extends Component {
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    validator: PropTypes.func,
    validationErrMsg: PropTypes.string,
    defaultValue: PropTypes.string,
    myProp: PropTypes.string,
    required: PropTypes.bool,
    suggestions: PropTypes.array,
    mapper: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    required: false,
    suggestions: [],
    defaultValue: '',
  };

  constructor(){
    super();
    this.initialState = {
      value: '',
      wasUsed: false,
      isEmptySubmit: false,
      isFalsySubmit: false,
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    }
    this.state = this.initialState;
    this.validator = () => true;
  }

  postChanges = (name, value) => {
    const { parentChange, mapper } = this.props;
    this.setState({ value, wasUsed: true });
    if(mapper) value = mapper(value);
    parentChange(name, escapeHtml(value));
  }
  
  handleChange = ({ currentTarget: { name, value }}) => {
    const { suggestions } = this.props;
    const filteredSuggestions = suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      wasUsed: true,
    });
    this.postChanges(name, value);
  }

  handleKeyDown = ({ keyCode }) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { name } = document.activeElement;
    const value = filteredSuggestions[activeSuggestion];
    if (keyCode === 13) {
      this.postChanges(name, value);
      this.resetSuggestions();
    } else if (keyCode === 38 && activeSuggestion > 0) {
      this.setState({
        activeSuggestion: activeSuggestion - 1,
        wasUsed: true,
      });
    } else if (keyCode === 40 && activeSuggestion < filteredSuggestions.length - 1) {
      this.setState({
        activeSuggestion: activeSuggestion + 1,
        wasUsed: true,
      });
    }
  }

  handleClickSuggestion = ({ currentTarget: { dataset, innerText } }) => {
    this.setState({ wasUsed: true });
    this.postChanges(dataset.name, innerText);
    this.resetSuggestions();
  }

  resetSuggestions = () => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    });
  }

  componentDidMount() {
    const { type, suggestions, validator } = this.props;

    this.setState({
      showSuggestions: !!suggestions.length,
      filteredSuggestions: suggestions,
    });

    const __validator = validate[type];

    if(!validator && __validator instanceof Function) {
      this.validator = __validator;
    } else {
      this.validator = validator;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      validator,
      state: { value },
      props: { name, defaultValue, required, submitStatus },
    } = this;

    if(prevProps.defaultValue !== defaultValue) {
      this.setState({ value: defaultValue });
      this.postChanges(name, defaultValue);
    }

    if(prevProps.submitStatus !== submitStatus) {
      if(submitStatus === 'checking') {
        if(!value && required) {
          this.setState({ isEmptySubmit: true });
        } else if(!validator(value)) {
          this.setState({ isFalsySubmit: true });
        }
      } else if(submitStatus === 'success') {
        this.setState(this.initialState);
      }
    }
  }

  render() {
    const {
      validator,
      handleClick,
      handleChange,
      handleKeyDown,
      handleClickSuggestion,
      props: {
        type,
        name,
        label,
        emptyErrMsg,
        validationErrMsg,
        required
      },
      state: {
        value,
        wasUsed,
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        isEmptySubmit,
        isFalsySubmit,
      }
    } = this;

    const cls = `input-wrapper
      ${wasUsed ? 'used-input' : ''}
      ${isEmptySubmit ? 'empty-input' : ''}`;

    const errorMsg = (isEmptySubmit || wasUsed) && !value ? emptyErrMsg :
      (isFalsySubmit || wasUsed) && !validator(value) ? validationErrMsg : null;
    
    const props = { name, label, value, required, onChange: handleChange };

    //console.log(`name: ${name}, value: ${value}`);

    return(
      <div className={cls}>
        {type === 'textarea' ? 
          <InputArea {...props} />
        :
          <InputField
            {...props}
            type={type}
            errorMsg={errorMsg}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          />
        }
        {showSuggestions && <ul className="suggestions">
          {filteredSuggestions.map((suggestion, idx) => {
            const itemCls = `suggestions__item ${
              idx === activeSuggestion ? 'suggestions__item--active' : ''
            }`;
            return(
              <li
                key={idx}
                data-name={name}
                className={itemCls}
                onClick={handleClickSuggestion}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>}
      </div>
    );
  }
}

export const InputField = ({
  type,
  name,
  label,
  value,
  errorMsg,
  onChange,
  onKeyDown,
  required,
}) => (
  <>
    <input
      name={name}
      type={type}
      value={value}
      pattern={validate[`${type}rx`]}
      onChange={onChange}
      onKeyDown={onKeyDown}
      required={required}
    />
    <label htmlFor={name} className={value ? 'nonempty-input' : ''}>{label}</label>
    {errorMsg && <div className="error-message">{errorMsg}</div>}
  </>
);

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
}

InputField.defaultProps = {
  value: '',
  required: false,
}

export const InputArea = ({
  name,
  label,
  value,
  onChange,
  required
}) => (
  <textarea
    name={name}
    placeholder={label}
    value={value}
    onChange={onChange}
    required={required}
    className={value ? 'nonempty-textarea' : ''}
  ></textarea>
);

InputArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

InputArea.defaultProps = {
  value: '',
  required: false,
}

export const Submit = ({ text, onClick, isSubmitting }) => (
  <div className={`button-wrapper ${isSubmitting ? 'animated' : ''}`}>
    <button onClick={onClick}>
      {text}
    </button>
  </div>
);

Submit.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

Submit.defaultProps = {
  isSubmitting: false,
}

export const Select = ({
  name, onChange, isEmpty, children
}) => (
  <div className={`input-wrapper ${isEmpty? 'empty-input' : ''}`}>
    <select name={name} onChange={onChange}>
      {children}
    </select>
  </div>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Select.defaultProps = {
  isEmpty: false,
}

export const Option = ({ value }) => (
  <option value={value}>
    {value}
  </option>
);

Option.propTypes = {
  value: PropTypes.string.isRequired,
}
