import React, { Component } from 'react';
import Login from '../building-blocks/login/login';
import logo from '../../logo.svg';
import './maintenance.css';

export default class Maintenance extends Component {
  constructor(){
    super();
    this.state = {
      adminLogged: false,
    }
  }
  
  loginCallback = (adminLogged) => {
    this.setState({ adminLogged });
  }

  render() {
    const { adminLogged } = this.state;
    const isCollapsed = adminLogged || window.sessionStorage.getItem('stoa_logged_in') === 'true';
    const cls = `maintenance-wrapper ${isCollapsed ? 'collapsed' : ''}`;
    return(
      <div className={cls}>
        <img src={logo} alt='stoa-logo' />
        <div className="maintenance-notification-wrapper">{/**/}
          <div className="maintenance-notification">{/**/}
            <div className="maintenance-notification__headline">
              Currently under maintenance
            </div>
            <div className="maintenance-notification__text">
              Our devs are hard at work updating the site.
              We appreciate your patience.
              Therefore we have made spinning cogs
              to delight you a little bit in the meantime.
            </div>
            <div className="maintenance-notification__cogs">{/**/}
              <Cog />
              <Cog />
              <Cog />
            </div>
          </div>
        </div>
        <Login loginCallback={this.loginCallback} />
      </div>
    );
  }
}

const Cog = () => (
  <span className="cog" aria-hidden="true">
    <svg viewBox="0 0 24 24">
      <path
        d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019
          v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285
          c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219
          h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001
          c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285
          c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219
          2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114
          2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375
          3.707-.847h.001c1.27.526 1.436 1.579 2.019
          3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524
          2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44
          3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"
        />
    </svg>
  </span>
);
